export default {
    types: (state) => state.types,
    success: (state) => state.success,
    error_msg: (state) => state.error_msg,
    live_ticket: (state) => state.live_ticket,
    assigned_user: (state) => state.assigned_user,
    live_message_data: (state) => state.live_message_data,
    tickets: (state) => state.tickets,
    inquiries_types: (state) => state.inquiries_typesm,
    single_ticket: (state) => state.single_ticket,
    replies_ticket: (state) => state.replies_ticket,
    ticket_log: (state) => state.ticket_log,
    count_unread_ticket: (state) => state.count_unread_ticket,
    not_delay: (state) => state.not_delay
}