// import axios from 'axios'
import axios from "axios";

export default {
  // link with samsa ( international search for order )
  async internationalSearch({ commit }, credentials) {
    await axios
      .get(`order/international-search?code=${credentials}`)
      .then((res) => {
        if (res.data.Tracking) {
          commit("DETAIL_OF_SAMSA_ORDER", res.data.Tracking[0]);
          commit("SUCCESS_API_SAMSA_ORDER", 1);
        } else {
          commit("SUCCESS_API_SAMSA_ORDER", 0);
          throw new Error("لا يوجد طرد شحن دولي برقم الكود المدخل");
        }
      })
      .catch((err) => {
        commit("ERROR_SAMSA_ORDER", err.message);
      });
  },
};
