var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.vueAppClasses,attrs:{"id":"app"},on:{"click":function($event){return _vm.inactiveTurboShop()}}},[_c('router-view',{on:{"setAppClasses":_vm.setAppClasses}}),_c('button',{ref:"notificationToast",staticStyle:{"display":"none"},attrs:{"color":_vm.notficationColor,"type":"flat"},on:{"click":function($event){_vm.$vs.notify({
      title: _vm.notficationTitle,
      text: _vm.$t(_vm.notficationText),
      color: _vm.notficationColor,
      iconPack: 'feather',
      icon: _vm.notficationIcon || 'icon-check'
    })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }