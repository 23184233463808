import state from './moduleTicketState.js'
import mutations from './moduleTicketMutations.js'
import actions from './moduleTicketActions.js'
import getters from './moduleTicketGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}