export default {
    types: [],
    success: '',
    error_msg: '',
    live_ticket: [],
    assigned_user: [],
    live_message_data: [],
    tickets: [],
    inquiries_types: [],
    single_ticket: [],
    replies_ticket: [],
    ticket_log: [],
    count_unread_ticket: [],
    showType: false,
    ticketPageIndex: 1,
    typeProblem: null,
    dataCreatTicket: {
        entityId: false,
        subTitle: '',
        complainTypeId: '',
        complainTypeTitleId: '',
        typeTicket: '',
        relatedModel: '',
        inquiryTypeId: ''
    },
    not_delay: false,
    ticket_instructions: [],
    err_instructions: ''
}