import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "https://2c8758aa3f7c48079da11ce11d196932@o930696.ingest.sentry.io/6429211",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [process.env.VUE_APP_BASE_URL],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

Vue.directive("click-outside", {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// axios
import "./axios";

// vue tour
import VueTour from "vue-tour";
import "vue-tour/dist/vue-tour.css";
Vue.use(VueTour);

// ACL
import acl from "./acl/acl";

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Idle
import IdleVue from "idle-vue";

// Toggle Button
import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);

const eventsHub = new Vue();
window.customEvents = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000,
});

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// Lottie
import LottieAnimation from "lottie-web-vue";

Vue.use(LottieAnimation);

// VeeValidate
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);

// import { Validator } from "vee-validate";
import dict from "./mixins/validatorDict";
Validator.localize("en", dict);

// vue infinite loading
import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading, {
  /* options */
});

// international phone
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput, {
  defaultCountry: 'EG',
  mode: 'international',
});


// Feather font icon
require("./assets/css/iconfont.css");

Vue.config.productionTip = false;
// io(process.env.VUE_APP_BASE_URL_SOCKET, {secure: true})
Vue.prototype.$socket = io(process.env.VUE_APP_BASE_URL_SOCKET, {
  secure: true,
});

new Vue({
  router,
  store,
  async onIdle() {
    await localStorage.removeItem("userData");
    const socket = io(process.env.VUE_APP_BASE_URL_SOCKET, { secure: true });
    socket.removeAllListeners();
    socket.disconnect();
    socket.close();
    this.$router.push({ name: "page-login" });
  },
  onActive() {},
  i18n,
  acl,
  render: (h) => h(App),
}).$mount("#app");
