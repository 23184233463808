export default {
    SET_TYPES: (state, types) => (state.types = types),
    SET_SUCCESS: (state, success) => (state.success = success),
    SET_ERROR_MSG: (state, error_msg) => (state.error_msg = error_msg),
    SET_LIVE_TICKET: (state, live_ticket) => (state.live_ticket = live_ticket),
    SET_ASSIGNED_USER: (state, assigned_user) => (state.assigned_user = assigned_user),
    SET_LIVE_MESSAGE_DATA: (state, live_message_data) => (state.live_message_data = live_message_data),
    SET_TICKETS: (state, tickets) => (state.tickets = tickets),
    SET_SINGLE_TICKET: (state, single_ticket) => (state.single_ticket = single_ticket),
    SET_REPLIES_TICKET: (state, replies_ticket) => (state.replies_ticket = replies_ticket.reverse()),
    SET_TICKET_LOG: (state, ticket_log) => (state.ticket_log = ticket_log),
    SET_COUNT_UNREAD_TICKET: (state, count_unread_ticket) => (state.count_unread_ticket = count_unread_ticket),
    SET_SHOW_TYPE: (state, showType) => (state.showType = showType),
    SET_NEXT_PAGE: (state) => (state.ticketPageIndex++),
    SET_PREVIOUS_PAGE (state) {
        state.ticketPageIndex--
        state.dataCreatTicket.entityId = false
    },
    SET_TYPE_PROBLEM: (state, type) => (state.typeProblem = type),
    SET_NEW_TICKET: (state) => { state.ticketPageIndex = 1, state.typeProblem = null },
    SET_COUNT: (state, ticketPageIndex) => (state.ticketPageIndex = ticketPageIndex ),

    SET_SUB_TITLE: (state, subTitle) => (state.dataCreatTicket.subTitle = subTitle),
    SET_COMPLAINT_TYPE_ID: (state, complainTypeId) => (state.dataCreatTicket.complainTypeId = complainTypeId),
    SET_COMPLAIN_TYPE_TITLE_ID: (state, complainTypeTitleId) => (state.dataCreatTicket.complainTypeTitleId = complainTypeTitleId),
    SET_TYPE_TICKET: (state, typeTicket) => (state.dataCreatTicket.typeTicket = typeTicket),
    SET_RELATED_MODEL: (state, relatedModel) => (state.dataCreatTicket.relatedModel = relatedModel),
    SET_INQUIRY_TYPE_Id: (state, inquiryTypeId) => (state.dataCreatTicket.inquiryTypeId = inquiryTypeId),
    SET_ENITY_ID: (state, entityId) => (state.dataCreatTicket.entityId = entityId),
    SET_NOT_DELAY: (state, not_delay) => (state.not_delay = not_delay),
    SET_TICKET_INSTRUCTIONS: (state, ticket_instructions) => (state.ticket_instructions = ticket_instructions),
    SET_ERR_INSTRUCTIONS: (state, err_instructions) => (state.err_instructions = err_instructions)
}