export default {
  client: [],
  sub_client: [],
  sub_user: [],
  responsible_branch: "",
  government: "",
  error_msg: "",
  total_supplied_balance: "",
  next_supply: "",
  success_api: "",
  total_balance: "",
  user: [],
  api_errors: [],
  updated_info: [],
  have_stock: null,
  client_locations: [],
  new_support_system_user:'',
  support_system_user_id:'',
};
