// Module Datalist (order data 'details, advanced search, sumbit, edit .....') & (mission data 'details, submit, edit ......')
// & (notifications) & (faq) & (help center) & (terms&conditions) & (all excel sheets 'orders report, mission reports ......')
import state from './moduleDataListState.js'
import mutations from './moduleDataListMutations.js'
import actions from './moduleDataListActions.js'
import getters from './moduleDataListGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

