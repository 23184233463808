export default {
  client: (state) => state.client,
  sub_client: (state) => state.sub_client,
  sub_user: (state) => state.sub_user,
  responsible_branch: (state) => state.responsible_branch,
  government: (state) => state.government,
  error_msg: (state) => state.error_msg,
  err_msg: (state) => state.error_msg,
  next_supply: (state) => state.next_supply,
  total_supplied_balance: (state) => state.total_supplied_balance,
  success_api: (state) => state.success_api,
  total_balance: (state) => state.total_balance,
  new_support_system_user: (state) => state.new_support_system_user,
  support_system_user_id: (state) => state.support_system_user_id,
  user: (state) => state.user,
  api_errors: (state) => state.api_errors,
  updated_info: (state) => state.updated_info,
  client_locations(state) {
    state.client_locations.map((existedLocation) => {
      existedLocation.name = existedLocation.region;
    });
    return state.client_locations;
  },
  have_stock: (state) => state.have_stock,
};
