export default {
  orders: [],
  feed_count: '',
  feed_count_missions: '',
  governments: [],
  payload: [],
  order_details: [],
  missions: [],
  feed_count_costs: '',
  costs: [],
  address_of_mission: [],
  missions_withiin_two_days: '',
  suppleid_available: '',
  bank_accounts: [],
  payment_way: [],
  successApi: '',
  notfication: [],
  detail_of_order: {},
  detail_of_mission: {},
  faq: [],
  branch_mission: '',
  client_mission: '',
  captain_mission: '',
  success: 0,
  log: [],
  order_for_mission: [],
  papers_for_mission: [],
  data_mission_print1: [],
  data_mission_print2: [],
  data_mission_print3: [],
  chart: [],
  delay_log: [],
  account_manager: [],
  terms_and_conditions: '',
  order_code: '',
  err_msg: '',
  success_msg: '',
  search_mission: [],
  max_order_updated_at: '',
  max_mission_updated_at: '',
  print_order: [],
  branches: [],
  captains: [],
  message: '',
  notification_control: [],
  success_resend: '',
  videos: [],
  success_api_rate: '',
  mission_canelation_reasons: [],
  err_mission_cancel_reasons: false,
  next_page_url_cancelation_reasons: "order/get_delay_reasons?type=5",
  supply_days: [],
  unpredicted_geos: [],
  success_api_confirm_suggest_area: ""
}
