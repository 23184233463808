export default {
  orders: (state) => state.orders,
  feed_count: (state) => state.feed_count,
  feed_count_missions: (state) => state.feed_count_missions,
  governments: (state) => state.governments,
  payload: (state) => state.payload,
  order_details: (state) => state.order_details,
  feed_count_costs: (state) => state.feed_count_costs,
  costs: (state) => state.costs,
  missions: (state) => state.missions,
  address_of_mission(state) {
    state.address_of_mission.map((existedAddress) => {
      existedAddress.name = existedAddress.address;
    });
    return state.address_of_mission;
  },
  missions_withiin_two_days: (state) => state.missions_withiin_two_days,
  suppleid_available: (state) => state.suppleid_available,
  bank_accounts(state) {
    state.bank_accounts.map((existedAcount) => {
      existedAcount.name = existedAcount.account_number;
    });
    return state.bank_accounts;
  },
  payment_way(state) {
    state.payment_way.map((existedPayment) => {
      existedPayment.name = existedPayment.title;
    });
    return state.payment_way;
  },
  notfication: (state) => state.notfication,
  detail_of_order: (state) => state.detail_of_order,
  detail_of_mission: (state) => state.detail_of_mission,
  faq: (state) => state.faq,
  branch_mission: (state) => state.branch_mission,
  client_mission: (state) => state.client_mission,
  captain_mission: (state) => state.captain_mission,
  success: (state) => state.success,
  success_api: (state) => state.successApi,
  log: (state) => state.log,
  order_for_mission: (state) => state.order_for_mission,
  papers_for_mission: (state) => state.papers_for_mission,
  data_mission_print1: (state) => state.data_mission_print1,
  data_mission_print2: (state) => state.data_mission_print2,
  data_mission_print3: (state) => state.data_mission_print3,
  chart: (state) => state.chart,
  delay_log: (state) => state.delay_log,
  account_manager: (state) => state.account_manager,
  terms_and_conditions: (state) => state.terms_and_conditions,
  order_code: (state) => state.order_code,
  err_msg: (state) => state.err_msg,
  success_msg: (state) => state.success_msg,
  search_mission: (state) => state.search_mission,
  max_order_updated_at: (state) => state.max_order_updated_at,
  max_mission_updated_at: (state) => state.max_mission_updated_at,
  print_order: (state) => state.print_order,
  branches: (state) => state.branches,
  captains: (state) => state.captains,
  message: (state) => state.message,
  notification_control: (state) => state.notification_control,
  success_resend: (state) => state.success_resend,
  videos: (state) => state.videos,
  success_api_rate: (state) => state.success_api_rate,
  mission_canelation_reasons(state) {
    state.mission_canelation_reasons.map((existedReason) => {
      existedReason.name = existedReason.reason;
    });
    return state.mission_canelation_reasons;
  },
  err_mission_cancel_reasons: (state) => state.err_mission_cancel_reasons,
  unpredicted_geos(state) {
    state.unpredicted_geos.map((existedUnpredicted_geo) => {
      existedUnpredicted_geo['display'] = true
    });
    return state.unpredicted_geos;
  }
};
