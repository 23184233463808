import axios from "axios";
export default {
  commitErros({ commit }, res) {
    commit("SET_ERRORS", res.data.errors);
    commit("SET_ERROR_MSG", res.data.error_msg);
    commit("SET_SUCCESS", res.data.success);
  },

  // fetch all products
  async fetchProducts({ commit, state }, payload) {
    await axios
      .get(`${state.next_products_url}&paginate=${payload.paginate}`)
      .then((res) => {
        if (payload.isAppend) {
          commit("SET_PRODUCTS", [...state.products, ...res.data.feed.data]);
        } else {
          commit("SET_PRODUCTS", [...state.products, ...res.data.feed.data]);
          commit("SET_NEW_PRODUCTS", res.data.feed.data);
        }
        commit("SET_NEXT_PRODUCTS_URL", res.data.feed.next_page_url);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // search in products
  async fetchSearchProducts({ commit }, credentials) {
    await axios
      .get(`products?page=${credentials.page}&search=${credentials.keyword}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit("SET_NEW_PRODUCTS", res.data.feed.data);
          commit("SET_SUCCESS", res.data.success);
          commit("SET_NEXT_PRODUCTS_URL_SEARCH", res.data.feed.next_page_url);
        } else {
          dispatch("commitErros", res);
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response);
      });
  },

  // submit new product
  async postProduct({ commit, state, dispatch }, credentials) {
    await axios
      .post("products", credentials)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit("SET_PRODUCTS", [res.data.feed, ...state.products]);
          commit("SET_SUCCESS", res.data.success);
        } else {
          dispatch("commitErros", res);
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response);
      });
  },

  // edit product
  async updateProduct({ commit, state, dispatch }, credentials) {
    await axios
      .put("products", credentials)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit(
            "SET_PRODUCTS",
            state.products.map((existedProduct) => {
              return existedProduct.id === credentials.id
                ? credentials
                : existedProduct;
            })
          );
          commit("SET_SUCCESS", res.data.success);
        } else {
          dispatch("commitErros", res);
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response);
      });
  },

  // delete product
  async deleteProduct({ commit, state, dispatch }, credentials) {
    await axios
      .delete(`products/delete/${credentials}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit(
            "SET_PRODUCTS",
            state.products.filter((existedProduct) => {
              return existedProduct.id !== credentials;
            })
          );
          commit("SET_SUCCESS", res.data.success);
        } else {
          dispatch("commitErros", res);
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response);
      });
  },
};
