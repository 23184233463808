// en, ar
export default {
  en: {
    turbo: "TURBO",
    login: "Login",
    welcomAgain: "Welcome for another time.",
    loginToYourAccount: "Login to your account from here.",
    cantEditAmountInThisStatus: "Can't Edit Amount In This Status",
    agreeTerms: "I agree to the terms and conditions",
    termsCondition: "Terms and conditions",
    userName: "User name",
    password: "Password",
    forgetPassword: "Forget password ?",
    welcomeInTurbo: `Welcome to Turbo Shipping and Logistics Company.`,
    theLargestShipping:
      "The largest and fastest shipping company in the country, enjoy a distinctive and fast shipping experience with more than 48 branches in all governorates.",
    typeProducts: "The type of products",
    yourCode: "Your code",
    accountSettings: "Account settings",
    commonQuestions: "Common questions",
    nextSupply: "The next supply",
    totalAccount: "Your total account balance",
    retractableBalance: "The retractable balance",
    numberOfOrder: "Number of orders delivered last week",
    deliveryDone: "Delivery is done",
    theTotal: "The total",
    pounds: "Pounds",
    order: "Order",
    latestSupport: "The latest support tickets",
    addNewTicket: "Add a new ticket",
    allSupportTickets: "All support tickets",
    quickSearch: "Quick search for the order",
    theOrderCode: "The order code",
    youCanSearchHear: "You can search here for any order and know its status.",
    writeHereCode: "Write here the order number, for example: 2235D",
    lookFor: "Look for",
    withRegards: "With regards",
    ticketStatus: "Ticket status",
    theSubjectComplaint: "The subject of the complaint",
    search: "Search",
    searchByGovernZone: "Search by Governorate/Zone",
    newSearch: "New search",
    home: "Home",
    addOrder: "Add order",
    addMission: "Add Mission",
    addExcel: "Add order with excel",
    orders: "Orders",
    returns: "Returns",
    reports: "Reports",
    underWay: "Under way",
    saveBeforeShip: "Saved before shippment",
    sentForShip: "Sent for shippment",
    delayed: "Delayed",
    withCaptain: "With captain",
    inProgress: "In progress",
    given: "Given",
    addWithWallet: "Add with wallet",
    trackingDelay: "Track delay",
    withCompany: "With company",
    returned: "Returned",
    resend: "Resend",
    allOrders: "All orders",
    missions: "Missions",
    costs: "Costs",
    client_costs: "Shipping costs",
    client_office_costs: "Office delivery shipping costs",
    from: "From",
    to: "To",
    deliveryCostBackSender: "Delivery Back Sender",
    deliveryCostBackReceiver: "Delivery Back Receiver",
    backCostWithoutDeliveryCost: "Back Without Delivery",
    backCostSender: "Back Sender",
    backCostReceiver: "Back Receiver",
    partialBackCost: "Partial Back Replace",
    governorate: 'Governorate',
    savedAndSentOrder: "Saved & Sent orders",
    sentDate: "Sender date",
    givenDate: "Given date",
    code: "Code",
    sender: "Sender",
    receiver: "Receiver",
    government: "Government",
    area: "Area",
    address: "Address",
    notes: "Notes",
    collectedAmount: "Collected amount",
    mustBeGreaterThan: "Must be greater than",
    branch: "Branch",
    actions: "Actions",
    dateOfRegistration: "Date of registration",
    captain: "Captain",
    dateOfCreations: "Date of creation",
    dueDate: "Due date",
    rightDate: "Right date",
    status: "Status",
    type: "Type",
    cash: "The amount",
    backup: "Backup",
    orderDetails: "Order details",
    orderData: "Order data",
    barcode: "Barcode",
    orderCode: "Order code",
    statusOfOrder: "Status of order",
    statusSaved: "Saved",
    statusSent: "Sent",
    statusUnderWay: "Underway",
    statusWithCaptain: "With captain",
    statusWithCompany: "Return with company",
    statusReturned: "Returned",
    statusGiven: "Done given",
    statusWallet: "With wallet",
    statusDeleted: "Deleted",
    statusResend: "Returned resent",
    statusMissing: "Missing return",
    statusExpiredReturn: "Expired return",
    statusDelayed: "Delayed",
    contentOrder: "Order content",
    notesOrder: "Order notes",
    willOrderBe: "Will order be receive from receiver",
    contentReturn: "Return order content",
    returnValue: "Return value",
    senderReceiverData: "Sender & Receiver data",
    maxDeliveryTime: "Max delivery time",
    senderNumber: "Sender number",
    companyTrackingNumber: "Company tracking number",
    deliveryCost: "Delivery cost",
    extraCost: "Extra cost",
    reason: "Reason",
    packagingCost: "Packaging cost",
    totalShippingPrice: "Total shipping price",
    receivingBranch: "Receiving branch",
    receivingBranchNumber: "Receiving branch number",
    branchNumber: "Branch number",
    executingBranch: "Executing branch",
    currentBranch: "Current branch",
    currentBranchNumber: "Current branch number",
    captainNumber: "Captain number",
    captainItinerary: "Captain's itinerary",
    branchSubordinate: "Subordinate branch",
    receiverNumber: "Receiver number",
    receiverWebsite: "Receiver website link",
    amountAlreadyCollected: "Amount already collected",
    amountCollect: "Amount collected",
    amountSupplied: "Amount supplied",
    orderTimes: "Order times",
    clientPaymentAmount: "Client payment amount",
    add: "Add",
    edit: "Edit",
    print: "Print",
    printPolicy: "Print Policy",
    printSticker: "Print Sticker",
    transToSent: "Trnsfare to sent",
    editOrder: "Edit order",
    displayAndEditMission: "Show & Edit mission",
    showMission: "Show all mission",
    downloadExcel: "Download excel files",
    missionKey: "Mission key",
    orderForMission: "Order for mission",
    paperMonyForMission: "Mony paper for mission",
    client: "Client",
    packagingValue: "packaging value",
    stockValue: "Stock Value",
    returnStatus: "return Status",
    mainSender: "Main sender",
    numberPaper: "Paper number",
    typeMove: "Type move",
    clientName: "Client name",
    value: "Value",
    creatBy: "Create by",
    of: "of",
    number1: "Phone number 1",
    number2: "Phone number 2",
    numbreTracking: "Order number phone tracking",
    save: "Save",
    cancel: "Cancel",
    paymentWay: "Payment way",
    accountNumber: "Account number",
    invoiceNumber: "Invoice number",
    profile: "Profile",
    chat: "Chat",
    logout: "Logout",
    startConversation: "Display conversation",
    send: "Send",
    success: "Success",
    addToast: "It was added successfully",
    editToast: "It was successfully modified",
    deleteToast: "Deleted successfully",
    searchToast: "Searched successfully",
    faild: "Failed",
    faildAdd: "Failed to add",
    faildEdit: "Failed to edit",
    faildDelete: "Failed to delete",
    faildSearch: "Failed to search",
    areYouDelete: "Are you sure to delete ?",
    willDelete: "It will permently deleted",
    accept: "Accept",
    notfications: "Notfications",
    showAllNotfications: "Show all notfications",
    theActionAlreadyExecuted: "The Action Already Executed",
    delete: "delete",
    doneChangeStatus: "Success change status",
    faildDoneChangeStatus: "Faild change status",
    welcomeBack: "Welcome back",
    back: "Back",
    mainInfo: "Main info",
    saveMission: "Save mission",
    tradeName: "The trade name",
    followUpNumber: "Follow-up number",
    theTotalBalance: "The total balance",
    theBalanceInWallet: "The balance in the wallet",
    name: "Name",
    mail: "E-mail",
    active: "Active",
    basicInfo: "Basic information",
    theNationalNumber: "The national number",
    customerCode: "Customer code",
    customerInfo: "Customer information",
    facebookLink: "Facebook link",
    administratorInfo: "The administrator's information",
    responsibleBranch: "The responsible branch",
    nameOfOfficial: "The name of the official",
    officialNumber: "The official number",
    theFollowUpName: "The name of the follow-up officer",
    numberOfFollowUp: "The number of the follow-up officer",
    shippingInfo: "Shipping information",
    extraKilogram: "The extra kilogram price",
    sellerCode: "The seller's code",
    sellerNotes: "Seller's notes",
    numberDeliveryDay: "The number of delivery days",
    locationInfo: "Location information",
    serviceInfo: "Service information",
    stockService: "Stock service",
    insuranceService: "Insurance service",
    requestService: "Request the service",
    alreadySubscribed: "Already subscribed",
    unsubscribe: "Unsubscribe",
    changePassword: "Change the password",
    oldPassword: "The old password",
    newPassword: "The new password",
    returnNewPassword: "Return the new password",
    basicClient: "Basic client",
    notSubscribed: "Not subscribed to the service",
    otpRequired: "OTP is required to access the api integration page.",
    toMakeSure:
      "To make sure you are the account owner, request a one-time password and enter it to access the API integration page.",
    otpRequest: "OTP request",
    saveModification: "Save the modifications",
    subUser: "Sub-user accounts",
    subCustomers: "Sub-customers - branches",
    linkingApi: "Linking API",
    donwloadOrderExcel: "Download the orders with Excel",
    followSteps: "Follow these steps to load your orders.",
    downloadDemo: "Download the demo version",
    downloadFile: "Download file",
    afterFillingData:
      "After filling the data correctly, upload the Excel file in xlsx format.",
    uploadFileAfter: "Upload the file after filling in the data.",
    formulaAllowed: "The formula allowed to be uploaded xlsx",
    chooseFile: "Choose the file",
    uploadFile: "Upload the file",
    pleaseWait: "Please wait",
    uploadingYourFile: "Uploading your file",
    fileHasBeenUploaded: "The file has been uploaded successfully",
    ordersHassBeenUploaded:
      "The orders file has been successfully uploaded in xlsx format and the order hav been added to the perserved.",
    unfortunatelyFile:
      "Unfrotuntely, the file was not uploaded due to the problem.",
    fileHasNotUploaded: "The file has not been uploaded",
    preservedOrder: "Preserved orders",
    showAllOrders: "Show all orders",
    events: "Events",
    showOrder: "Show order",
    logOrder: "Log of order",
    packaging: "Packaging service",
    returnReason: "The reason for the reflux",
    typeOfReturn: "Type of return",
    dateOfSenderReturn: "The date of the offspring return",
    dateAddedtoWallet: "Date added to the wallet",
    newTicket: "New ticket",
    complaintOrProblem: "A complaint or a problem",
    inquiryOrQuestion: "Inquiry or question",
    thanksOrSuggestion: "Thanks or suggestion",
    allMissions: "All missions",
    supplied: "Supplied",
    chooseThedetailOfTheProblem: "Choose the details of the problem",
    causeOfProblem: "The cause of the problem",
    detailsOfProblem: "Details of the problem",
    captainName: "Captain's name",
    chooseBranch: "Choose the branch",
    ticketSupport: "The tickets support",
    infoAboutTicket: "Information about the ticket",
    itWasCreated: "It was created",
    laseEdit: "The last edit",
    orderNumberOrMission: "Order number or mission",
    responsibleEmployee: "The responsible employee",
    myInformation: "My information",
    mobileNumber: "Mobile number",
    recordTheModificationsTicket: "Record the modifications to the ticket",
    ticketSections: "Ticket sections",
    sorryNoMessageYet: "Sorry, there are no message yet.",
    chooseOrderYouWantConversation:
      "Choose the order you want to start the conversation about easily from here.",
    sorryNoTicket: "You don't have support tickets now.",
    youCanCreatSupportTicket:
      "You can easily create a support ticket to solve the problem by the competent department.",
    sorryDataTable: "There is no data in this table.",
    allDatesOfOreder: "All the dates of the order",
    registration: "Registration",
    sending: "Sending",
    acceptTransfer: "Accept the transfer",
    postponement: "Postponement",
    implementation: "Implementation",
    supply: "Supply",
    returnOnSender: "Return on the sender",
    deliveryTime: "Delivery time",
    priceOfExtraKilo: "The price of the extra kilo",
    returnCost: "The cost of return",
    captainNotes: "Captain's notes",
    insuranceCost: "The cost of insurance",
    collectionCost: "The cost of collection",
    storageCost: "The cost of storage",
    statusNew: "New",
    forCaptain: "For captain",
    givenForCaptain: "Given for captain",
    exhausted: "Exhausted",
    statusCanceled: "Canceled",
    executedWithCaptain: "Executed with the captain",
    canceledWithCaptain: "Canceled with the captain",
    printingAids: "Printing aids",
    excelAids: "Excel aids",
    excelReturnedOrders: "Excel returned orders",
    excelDeliveredOrders: "Excel delivered orders",
    excelSecurities: "Excel securities (AXIL)",
    supply2: "Supply",
    receivingAndSupplying: "Receiving and supplying",
    receive: "Receive",
    returnsOrReceiptsAndReturns: "Returns / receipts and returns",
    downloadOrderReport: "Download the order report within a specified period.",
    downloadMissionReport:
      "Download the missions report within a specified period.",
    chooseDateYouWantDownloadExcelMission:
      "Choose the date you want to download your missions report in and then download the excel file.",
    chooseDateYouWantDownloadExcelOrder:
      "Choose the date you want to download your orders report in and then download the excel file.",
    fromDuration: "From the duration",
    toDuration: "To the duration",
    downloadReport: "Download report",
    chooseDate: "Choose the date",
    basedOn: "Based on",
    optional: "Optional",
    doYouWantDeliverOrderNearstBranch:
      "Do you want to deliver the order at the nearest branch ? (Delivery of books)",
    exampleMainBranch: "Example: the main branch",
    exampleNumber: "Example: 01000000000",
    exampleGovernorate: "Example: Cairo Governorate",
    exampleArea: "Example: El Sayda Zainab",
    exampleAddress: "Example: 12 Port Said Street, first floor.",
    exampleNotes: "Example: The order is breakable.",
    exampleInvoice: "Example: 27",
    exampleContentOrder: "Example: Glasses",
    exampleAmountOrValue: "Example: 1250 Pound",
    theDateOfDelivery: "The date of delivery",
    searchResults: "Search results",
    sendMeLink: "Send me this link",
    copiesDone: "Copies have been done",
    enterNextHeader: "Enter the next in the header",
    replayCaseSuccess: "Reply in case of success",
    apiCode: "The API code",
    searchForOrder: "Search for a order",
    ordersCases: "Orders cases",
    deleteOrder: "Delete a order",
    new: "New",
    advancedSearch: "Advanced search",
    identicalPassword: "It must be identical to the initial password.",
    helpCenter: "Help center",
    reportDate: "The reporting date",
    admin: "Admin",
    adminNotes: "Admin notes",
    packagingServicePercentage: "Packaging service percentage",
    shipChost: "Shipmint cost",
    returnOrders: "Return orders",
    givenOrders: "Given orders",
    addEmployee: "Add employee",
    successConnectionInternet: "The internet connection succeeded",
    failedConnectionInternet: "Internet connection failed",
    myProducts: "My Products",
    checkLocation: "Check Location",
    fingerprintRange: "Fingerprint range",
    latitude: "latitude",
    longitude: "longitude",
    hisLocation: "His location",
    removeDevice: "Remove device id",
    notificationSetting: "Notification setting",
    linkWithPlugin: "Link with plugins",
    theAreaIsOutOfCoverage: "The area is out of coverage",
    ChooseReasonCancellation: "Choose the reason",
    duringDuration: "During the duration",
    uncompletedOrder: "Uncompleted order",
    statusUncompleted: "Status uncompleted",
    resetSearch: "Reset Search"
  },

  ar: {
    turbo: "تربو",
    login: "تسجيل الدخول",
    welcomAgain: "أهلا بيك مره تانيه",
    loginToYourAccount: "أدخل علي حسابك من هنا",
    agreeTerms: "أوافق علي االشروط والأحكام",
    termsCondition: "الشروط والأحكام",
    userName: "اسم المستخدم",
    password: "كلمة المرور",
    forgetPassword: "نسيت كلمة المرور ؟",
    welcomeInTurbo: `مرحبا بك في شركة Turbo للشحن والخدمات اللوجيستية`,
    theLargestShipping:
      "أكبر وأسرع شركة شحن علي مستوي الجمهورية, استمتع بتجربة شحن مميزة وسريعة مع عدد تجاوز ال 50 فرع في جميع المحافظات",
    typeProducts: "نوع المنتجات",
    yourCode: "الكود الخاص بك",
    accountSettings: "إعدادات الحساب",
    commonQuestions: "الاسئلة الشائعة",
    nextSupply: "التوريد القادم",
    totalAccount: "إجمالي رصيد حسابك",
    retractableBalance: "الرصيد القابل للتوريد",
    numberOfOrder: "الطرود المسلمة أخر 30 يوم",
    deliveryDone: "تم التسليم",
    theTotal: "الإجمالي",
    pounds: "جنيه",
    order: "طرد",
    latestSupport: "أخر تذاكر الدعم",
    addNewTicket: "إنشاء تذكرة جديدة",
    allSupportTickets: "جميع تذاكر الدعم",
    quickSearch: "البحث السريع عن الطرد",
    theOrderCode: "كود الطرد",
    youCanSearchHear: "يمكنك البحث هنا عن اي طرد ومعرفة حالته",
    writeHereCode: "إكتب هنا رقم الطرد مثال: 2235D",
    lookFor: "ابحث",
    withRegards: "بخصوص",
    ticketStatus: "حالة التذكرة",
    theSubjectComplaint: "موضوع الشكوي",
    search: "بحث",
    searchByGovernZone: "بحث محافظة/منطقة",
    newSearch: "بحث جديد",
    home: "الرئيسية",
    addOrder: "إضافة طرد",
    addMission: "إضافة مهمة",
    addExcel: "إضافة طرود بالإكسل",
    orders: "الطرود",
    returns: "المرتجعات",
    reports: "التقارير",
    underWay: "قيد التنفيذ",
    saveBeforeShip: "المحفوظة قبل الشحن",
    sentForShip: "المرسلة للشحن",
    delayed: "المؤجلة",
    withCaptain: "مع الكابتن",
    inProgress: "قيد التسليم",
    given: "المسلمة",
    addWithWallet: "المضاف بالمحفظة",
    trackingDelay: "متابعة التأجيلات والتأخيرات",
    withCompany: "مع الشركة",
    returned: "مرتجعة",
    resend: "معاد إرسالها",
    allOrders: "جميع الطرود",
    missions: "المهمات",
    costs: "التكاليف",
    client_costs: "تكاليف الشحن",
    client_office_costs: "تكاليف شحن تسليم مكتب",
    from: "من",
    to: "الي",
    deliveryCostBackSender: "شحن مرتجع علي الراسل",
    deliveryCostBackReceiver: "شحن مرتجع مدفوع",
    backCostWithoutDeliveryCost: "مرتجع بدون شحن",
    backCostSender: "مرتجع علي الراسل",
    backCostReceiver: "مرتجع علي المرسل إليه",
    partialBackCost: "مرتجع جزئي",
    governorate: 'محافظة',
    savedAndSentOrder: "الطرود المحفوظة والمرسلة",
    sentDate: "تاريخ الإرسال",
    givenDate: "تاريخ الإستلام",
    code: "الكود",
    sender: "الراسل الفرعي",
    receiver: "المرسل إليه",
    government: "المحافظة",
    area: "المنطقة",
    address: "العنوان",
    notes: "ملاحظات",
    collectedAmount: "المبلغ المطلوب تحصيله",
    mustBeGreaterThan: "يجب أن يكون أكبر من",
    branch: "الفرع",
    actions: "الأوامر",
    dateOfRegistration: "تاريخ التسجيل",
    captain: "الكابتن",
    dateOfCreations: "تاريخ الإنشاء",
    dueDate: "تاريخ التنفيذ",
    rightDate: "التاريخ المناسب",
    status: "الحالة",
    type: "النوع",
    cash: "المبلغ",
    backup: "بيك اب",
    orderDetails: "تفاصيل الطرد",
    orderData: "بيانات الطرد",
    barcode: "الباركود",
    orderCode: "كود الطرد",
    statusOfOrder: "حالة الطرد",
    statusSaved: "محفوظة",
    statusSent: "مرسلة",
    statusUnderWay: "قيد التنفيذ",
    statusWithCaptain: "مع الكابتن",
    statusWithCompany: "مرتجع مع الشركة",
    statusReturned: "مرتجعة",
    statusGiven: "تم التسليم",
    statusWallet: "بالمحفظة",
    statusDeleted: "محذوفة",
    statusResend: "مرتجعة معاد إرسالها",
    statusMissing: "مرتجع مفقود",
    statusExpiredReturn: "مرتجع معدوم",
    statusDelayed: "مؤجلة",
    contentOrder: "محتوي الطرد",
    notesOrder: "ملاحظات الطرد",
    willOrderBe: "هل سيتم استلام طرد من المرسل إليه ؟",
    contentReturn: "محتوي الطرد الراجع",
    returnValue: "قيمة الارتجاع",
    senderReceiverData: "بيانات الراسل والمرسل اليه",
    maxDeliveryTime: "اقصي مدة تسليم",
    senderNumber: "رقم الراسل",
    companyTrackingNumber: "رقم متابعة الشركة",
    deliveryCost: "تكلفة الشحن للمحافظة",
    extraCost: "التكلفة الاضافية",
    reason: "السبب",
    packagingCost: "تكلفة التغليف",
    totalShippingPrice: "اجمالي سعر الشحن",
    receivingBranch: "الفرع المستلم",
    receivingBranchNumber: "رقم الفرع المستلم",
    branchNumber: "رقم الفرع",
    executingBranch: "الفرع المنفذ",
    currentBranch: "الفرع الحالي",
    cantEditAmountInThisStatus: "لا يمكن تعديل المبلغ لهذه الحالة",
    currentBranchNumber: "رقم الفرع الحالي",
    captainNumber: "رقم الكابتن",
    captainItinerary: "خط سير الكابتن",
    branchSubordinate: "الفرع التابع له",
    receiverNumber: "رقم المرسل اليه",
    receiverWebsite: "رابط موقع المرسل اليه",
    amountAlreadyCollected: "المبلغ المحصل بالفعل",
    amountCollect: "المبلغ المحصل",
    amountSupplied: "المبلغ المورد",
    orderTimes: "توقيتات الطرود",
    clientPaymentAmount: "قام العميل بدفع مبلغ",
    add: "إضافة",
    edit: "تعديل",
    print: "طباعة",
    printPolicy: "طباعة البوليصة",
    printSticker: "طباعة ستيكر",
    transToSent: "تحويل إلي مرسلة",
    editOrder: "تعديل طرد",
    displayAndEditMission: "عرض وتعديل المهمات",
    showMission: "عرض جميع المهمات",
    downloadExcel: "تحميل ملفات اكسيل",
    missionKey: "مفتاح المهمة",
    orderForMission: "الطرود المعينة للمهمة",
    paperMonyForMission: "الأوراق المالية المعينة للمهمة",
    client: "العميل",
    packagingValue: "قيمة التغليف",
    stockValue: "قيمة الاستوك",
    returnStatus: "حالة مرتجع",
    mainSender: "الراسل الرئيسي",
    numberPaper: "رقم الورقة",
    typeMove: "نوع الحركة",
    clientName: "اسم العميل",
    value: "القيمة",
    creatBy: "إنشاء بواسطة",
    of: "من",
    number1: "رقم تليفون 1",
    number2: "رقم تليفون 2",
    numbreTracking: "رقم متابعة الطرد",
    save: "حفظ",
    cancel: "إلغاء",
    paymentWay: "طريقة الدفع",
    accountNumber: "رقم الحساب",
    invoiceNumber: "رقم الفاتورة",
    profile: "الصفحة الشخصية",
    chat: "المحادثات",
    logout: "تسجيل الخروج",
    startConversation: "عرض المحادثات",
    send: "إرسال الطرد",
    success: "نجاح",
    addToast: "تمت الإضافة بنجاح",
    editToast: "تم التعديل بنجاح",
    deleteToast: "تم الحذف بنجاح",
    searchToast: "تم البحث بنجاح",
    faild: "فشل",
    faildAdd: "فشل في الإضافة",
    faildEdit: "فشل في التعديل",
    faildDelete: "فشل في الحذف",
    faildSearch: "فشل في البحث",
    areYouDelete: "هل انت متأكد من الحذف ؟",
    willDelete: "سيتم الحذف نهائيا",
    accept: "موافق",
    notfications: "الإشعارات",
    showAllNotfications: "عرض جميع الإشعارات",
    theActionAlreadyExecuted: "تم اتخاذ هذا الاجراء من قبل",
    delete: "حذف",
    doneChangeStatus: "تم التحويل بنجاح",
    faildDoneChangeStatus: "فشل في التحويل",
    welcomeBack: "مرحبا بعودتك",
    back: "رجوع",
    mainInfo: "المعلومات الأساسية",
    saveMission: "حفظ المهمة",
    tradeName: "الاسم التجاري",
    followUpNumber: "رقم المتابعة",
    theTotalBalance: "الرصيد الكلي",
    theBalanceInWallet: "الرصيد بالمحفظة",
    name: "الاسم",
    mail: "البريد الإلكتروني",
    active: "نشط",
    basicInfo: "المعلومات الأساسية",
    theNationalNumber: "الرقم القومي",
    customerCode: "كود العميل",
    customerInfo: "معلومات العميل",
    facebookLink: "رابط الفيسبوك",
    administratorInfo: "معلومات المسئول",
    responsibleBranch: "الفرع المسئول",
    nameOfOfficial: "اسم المسئول",
    officialNumber: "رقم المسئول",
    theFollowUpName: "اسم مسئول المتابعة",
    numberOfFollowUp: "رقم مسئول المتابعة",
    shippingInfo: "معلومات الشحن",
    extraKilogram: "سعر الكيلو الإضافي",
    sellerCode: "كود البائع",
    sellerNotes: "ملاحظات البائع",
    numberDeliveryDay: "عدد أيام التسليم",
    locationInfo: "معلومات الموقع",
    serviceInfo: "معلومات الخدمات",
    stockService: "خدمة الاستوك",
    insuranceService: "خدمة التأمين",
    requestService: "طلب الخدمة",
    alreadySubscribed: "مشترك بالفعل",
    unsubscribe: "إلغاء الاشتراك",
    changePassword: "تغيير كلمة المرور",
    oldPassword: "كلمة السر القديمة",
    newPassword: "كلمة السر الجديدة",
    returnNewPassword: "إعادة كلمة السر الجديدة",
    basicClient: "عميل أساسي",
    notSubscribed: "غير مشترك بالخدمة",
    otpRequired: "مطلوب OTP للوصول إلي صفحة تكامل API",
    toMakeSure:
      "للتأكد من أنك مالك الحساب ، اطلب كلمة مرور لمرة واحدة وأدخلها للوصول إلى صفحة تكامل واجهة برمجة التطبيقات.",
    otpRequest: "طلب OTP",
    saveModification: "حفظ التعديلات",
    subUser: "حسابات الموظفين",
    subCustomers: "العملاء الفرعيين",
    linkingApi: "الربط API",
    donwloadOrderExcel: "تحميل الطرود بالإكسل",
    followSteps: "اتبع الخطوات التالية لتحميل الطرود الخاصة بك :",
    downloadDemo: "1. قم بتحميل النسخة التوضحية من",
    downloadFile: "تحميل ملف",
    afterFillingData:
      "2. قم برفع ملف الاكسيل بصيغة (.xlsx) بعد ملئ البيانات بشكل صحيح .",
    uploadFileAfter: "قم برفع الملف بعد ملئ بياناتك",
    formulaAllowed: "الصيغة المسموح برفعها هي (.xlsx)",
    chooseFile: "اختر الملف",
    uploadFile: "رفع الملف",
    pleaseWait: "برجاء الانتظار …",
    uploadingYourFile: "جاري رفع الملف الخاص بك",
    fileHasBeenUploaded: "تم رفع الملف بنجاح",
    ordersHassBeenUploaded:
      "تم رفع ملف الطرود بنجاح بصيغة (.xlsx) واضافة الطرود في الطرود المحفوظة",
    unfortunatelyFile: "للاسف، لم يتم رفع الملف بسبب مشكلة",
    fileHasNotUploaded: "لم يتم رفع الملف",
    preservedOrder: "الطرود المحفوظة",
    showAllOrders: "عرض جميع الطرود",
    events: "الأحداث",
    showOrder: "عرض الطرد",
    logOrder: "سجل الطرد",
    packaging: "خدمة التغليف",
    returnReason: "سبب الإرتجاع",
    typeOfReturn: "نوع المرتجع",
    dateOfSenderReturn: "تاريخ الارتجاع للراسل",
    dateAddedtoWallet: "تاريخ الاضافة بالمحفظة",
    newTicket: "تذكرة جديدة",
    complaintOrProblem: "شكوي أو مشكلة",
    inquiryOrQuestion: "استفسار",
    thanksOrSuggestion: "شكر أو إقتراح",
    allMissions: "جميع المهمات",
    supplied: "الموردة",
    chooseThedetailOfTheProblem: "إختر تفاصيل المشكلة",
    causeOfProblem: "سبب المشكلة",
    detailsOfProblem: "تفاصيل المشكلة",
    captainName: "اسم الكابتن",
    chooseBranch: "إختر الفرع",
    ticketSupport: "تذاكر الدعم",
    infoAboutTicket: "معلومات عن التذكرة",
    itWasCreated: "تم الإنشاء",
    laseEdit: "أخر تعديل",
    orderNumberOrMission: "رقم الطرد أو المهمة",
    responsibleEmployee: "الموظف المسئول",
    myInformation: "معلوماتي",
    mobileNumber: "رقم التيليفون",
    recordTheModificationsTicket: "سجل التعديلات علي التذكرة",
    ticketSections: "أقسام التذاكر",
    sorryNoMessageYet: "عفوا لا يوجد رسائل حتي الأن",
    chooseOrderYouWantConversation:
      "إختر الطرد المراد بدأ المحادثة عنه بسهولة من هنا",
    sorryNoTicket: "ليس لديك تذاكر دعم الأن",
    youCanCreatSupportTicket:
      "يمكنك إنشاء تذكرة دعم بسهولة لحل المشكلة من قبل القسم المختص",
    sorryDataTable: "لا يوجد بيانات في هذا الجدول",
    allDatesOfOreder: "جميع التواريخ الخاصة بالطرد",
    registration: "التسجيل",
    sending: "الإرسال",
    acceptTransfer: "قبول التحويل",
    postponement: "التأجيل",
    implementation: "التنفيذ",
    supply: "التوريد",
    returnOnSender: "الإرتجاع للراسل",
    deliveryTime: "ميعاد التسليم",
    priceOfExtraKilo: "سعر الكيلو الإضافي",
    returnCost: "تكلفة المرتجع",
    captainNotes: "ملاحظات الكابتن",
    insuranceCost: "تكلفة التأمين",
    collectionCost: "تكلفة التحصيل",
    storageCost: "تكلفة التخزين",
    statusNew: "جديدة",
    forCaptain: "معينة للكابتن",
    givenForCaptain: "مسلمة للكابتن",
    exhausted: "منفذة",
    statusCanceled: "ملغاة",
    executedWithCaptain: "منفذة مع الكابتن",
    canceledWithCaptain: "ملغاة مع الكابتن",
    printingAids: "طباعة المعينات",
    excelAids: "اكسيل المعينات",
    excelReturnedOrders: "اكسيل الطرود المرتجعة",
    excelDeliveredOrders: "اكسيل الطرود المسلمة",
    excelSecurities: "اكسيل الاوراق المالية",
    supply2: "توريد",
    receivingAndSupplying: "إستلام وتوريد",
    receive: "إستلام",
    returnsOrReceiptsAndReturns: "مرتجعات  إستلام ومرتجعات",
    downloadOrderReport: "تحميل تقرير الطرود خلال مدة محددة",
    downloadMissionReport: "تحميل تقرير المهمات خلال مدة محددة",
    chooseDateYouWantDownloadExcelMission:
      "إختر التاريخ المراد تحميل تقرير المهمات الخاصة بك فيه ثم قم بتحميل ملف الاكسيل",
    chooseDateYouWantDownloadExcelOrder:
      "إختر التاريخ المراد تحميل تقرير الطرود الخاصة بك فيه ثم قم بتحميل ملف الاكسيل",
    fromDuration: "من المدة",
    toDuration: "إلي المدة",
    downloadReport: "تحميل التقرير",
    chooseDate: "إختر التاريخ",
    basedOn: "بناء علي",
    optional: "إختياري",
    doYouWantDeliverOrderNearstBranch:
      "هل تريد تسليم الطرد بأقرب فرع ؟ (تسليم مكتب)",
    exampleMainBranch: "مثال: الفرع الرئيسي",
    exampleNumber: "مثال: 01000000000",
    exampleGovernorate: "مثال: محافظة القاهرة",
    exampleArea: "مثال: السيدة زينب",
    exampleAddress: "مثال: 12 شارع بورسعيد, الدور الأول",
    exampleNotes: "مثال: الطرد قابل للكسر",
    exampleInvoice: "مثال: 27",
    exampleContentOrder: "مثال: نظارات",
    exampleAmountOrValue: "مثال: 1250 جنيه",
    theDateOfDelivery: "تاريخ التسليم",
    searchResults: "نتائج البحث",
    sendMeLink: "أرسل علي هذا الرابط",
    copiesDone: "تم النسخ",
    enterNextHeader: "أدخل التالي في header",
    replayCaseSuccess: "الرد في حالة النجاح",
    apiCode: "كود ال API",
    searchForOrder: "بحث عن طرد",
    ordersCases: "حالات الطرود",
    deleteOrder: "حذف طرد",
    new: "جديد",
    advancedSearch: "البحث المتقدم",
    identicalPassword: "يجب أن تكون متطابقة مع كلمة السر الأولي",
    helpCenter: "مركز المساعدة",
    reportDate: "تاريخ الإبلاغ",
    admin: "الادمن",
    adminNotes: "ملاحظات الادمن",
    packagingServicePercentage: "نسبة التغليف",
    shipChost: "تكلفة الشحن",
    returnOrders: "الطرود المرتجعة",
    givenOrders: "الطرود المسلمة",
    addEmployee: "إضافة موظف",
    successConnectionInternet: "نجح الاتصال بالانترنت",
    failedConnectionInternet: "فشل الاتصال بالانترنت",
    myProducts: "منتجاتي",
    checkLocation: "تحديد اطار الموقع",
    fingerprintRange: "نطاق البصمة",
    latitude: "دائرة العرض",
    longitude: "خط الطول",
    hisLocation: "المنطقة التابع لها",
    removeDevice: "حذف معرف الجهاز",
    notificationSetting: "إعدادات الإشعارات",
    linkWithPlugin: "الربط بالإضافات",
    theAreaIsOutOfCoverage: "المنطقة خارج التغطية",
    ChooseReasonCancellation: "اختر السبب",
    duringDuration: "خلال مدة",
    uncompletedOrder: "الطرود الغير مكتملة",
    statusUncompleted: "غير مكتمل",
    resetSearch: "كل المهمات"
  },
};
