export default {
  // CHAT ORDER
  SET_CONVERSATION: (state, conversations) => (state.conversations = conversations),
  SET_CHAT: (state, chat) => (state.chat = chat.reverse()),
  SET_INFO_CHAT: (state, info_chat) => (state.info_chat = info_chat),
  // CHAT MISSION 
  SET_CONVERSATION_MISSION: (state, conversations_mission) => (state.conversations_mission = conversations_mission),
  SET_CHAT_MISSION: (state, chat_mission) => (state.chat_mission = chat_mission.reverse()),
  SET_INFO_CHAT_MISSION: (state, info_chat_mission) => (state.info_chat_mission = info_chat_mission),
  // STATUS POST MESSAGE
  SET_SUCCESS_API: (state, value) => (state.successApi = value),
  SET_ERROR_MESSAGE: (state, err_msg) => (state.err_msg = err_msg)
}

