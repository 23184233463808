export default {
    ERROR_MSG: (state, error_msg) => (state.error_msg = error_msg),
    SET_SUCCESS: (state, success) => (state.success = success),
    SET_PHONE: (state, phone) => (state.phone = phone),
    SET_EMAIL: (state, email) => (state.email = email),
    SET_CODE: (state, code) => (state.code = code),
    DELETED_ACCOUNT: (state, deleted) => (state.deleted_account = deleted),
    phone1: (state, phone) => (state.phone1 = phone),
    phone2: (state, phone) => (state.phone2 = phone),
    STATUS_CODE: (state, status_code) => (state.status_code = status_code),
}
