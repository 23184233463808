// import axios from 'axios'
import axios from 'axios';
import { createNamespacedHelpers } from 'vuex';

export default {
  // fetch order items
  async fetchDataListItems({ commit }, credentials) {
    let response = '';
    if (credentials.pageNum != null) {
      response = await axios.post(
        `${credentials.page}?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}&max_order_updated_at=${credentials.maxOrderUpdatedAt}&code&invoice_number&second_client&receiver&phone1&government_id&area`
      );
    } else {
      response = await axios.post(
        `${credentials.page}?perPage=${credentials.itemsPerPage}&max_order_updated_at=null&code&invoice_number&second_client&receiver&phone1&government_id&area`
      );
    }
    commit('SET_ORDERS', response.data.response.feed);
    commit('FEED_COUNT', response.data.response.feed_count);
    if (response.data.response.max_order_updated_at) {
      commit('SET_MAX_ORDER_UPDATED_AT', response.data.response.max_order_updated_at);
    }
    commit('SUCCESS', response.data.response.success);
  },

  // Get government id & Payload
  async fetchGovData({ commit }) {
    const response = await axios.post(`order/add`);
    // Gov
    commit('SET_GOV', response.data.feed);
    // Payload
    commit('SET_PAYLOAD', response.data.payload_feed);
  },

  // submit Order
  async postOrder({ commit }, credentials) {
    const response = await axios.post(`order/submit`, credentials);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_ORDER_CODE', response.data.code);
    }
  },

  // submit Orders by Excel
  async postOrderExcel({ commit }, credentials) {
    var formData = new FormData();
    formData.append('import_file', credentials);
    const response = await axios.post(`order/add-excelsheet`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_ERROR_MSG', response.data.error_msg);
      if (response.data.error_msg.includes('تحميله')) {
        commit('SET_ERROR_MSG', 'old');
      }
    } else {
      commit('SET_SUCCESS_API', 1);
    }
  },

  // submit Orders by Smart Excel (AI)
  async postOrderSmartExcel({ commit }, credentials) {
    var formData = new FormData();
    formData.append('import_file', credentials);
    const response = await axios.post(`order/add-excelsheet/smart`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_ERROR_MSG', response.data.error_msg);
      if (response.data.error_msg.includes('تحميله')) {
        commit('SET_ERROR_MSG', 'old');
      }
    } else {
      if (response.data.unpredected_geos && response.data.unpredected_geos.length) {
        commit('SET_SUCCESS_API', 0);
        commit('SET_UNPREDICTED_GEOS', response.data.unpredected_geos);
        commit('SET_ERROR_MSG', response.data.success_msg);
      } else {
        commit('SET_UNPREDICTED_GEOS', []);
        commit('SET_SUCCESS_API', 1);
        commit('SET_SUCCESS_MSG', response.data.success_msg);
      }
    }
  },

  // Confirm if Suggest Area Is Correct (AI)
  async confirmSuggestAreaInSmartExcel({ commit }, credentials) {
    const response = await axios.post(`order/add-excelsheet/correct`, credentials);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API_CONFIRM_SUGGEST_AREA', 0);
    } else {
      commit('SET_SUCCESS_API_CONFIRM_SUGGEST_AREA', 1);
    }
  },

  // fetch order data for edit it
  async editOrder({ commit }, id) {
    const response = await axios.post(`order/edit?id=${id}`);
    // Gov
    commit('SET_GOV', response.data.feed.governments);
    // Order Details
    commit('ORDER_DETAILS', response.data.feed.order);
    // Payload
    commit('SET_PAYLOAD', response.data.payload_feed);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('SET_SUCCESS_API', 1);
    }
  },

  // Advanced Search for orders
  async advancedSearch({ commit }, credentials) {
    const response = await axios.post(`order/advanced-search-submit`, {
      status: credentials.status,
      code: credentials.code,
      bar_code: credentials.barcode,
      invoice_number: credentials.invoiceNumber,
      second_client: credentials.sender,
      receiver: credentials.receiver,
      phone1: credentials.receiverNumber,
      area: credentials.area,
      government_id: credentials.govId,
      captain_id: credentials.captainId,
      branch_id: credentials.branchId,
      created_at: credentials.dateOfRegistration,
      amount_to_be_collected: credentials.collectedAmount,
      received_amount: credentials.amountCollect,
      sent_at: credentials.sentDate,
      delivery_date: credentials.theDateOfDelivery,
      execution_date: credentials.dueDate,
      cash_amount: credentials.amountSupplied,
      return_to_sender_date: credentials.dateOfSenderReturn,
      deposit_date: credentials.dateAddedtoWallet,
      return_reason: credentials.returnReason,
      page: credentials.page,
      perPage: credentials.perPage,
    });
    await commit('SET_ORDERS', response.data.response.feed);
    commit('FEED_COUNT', response.data.response.feed_count);
    commit('SUCCESS', response.data.response.success);
  },

  // Advanced Search for missions
  async missionsAdvancedSearch({ commit, state }, credentials) {
    const response = await axios.post(`mission/all?max_mission_updated_at=${state.max_mission_updated_at}&page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}`,{
      captain_id:credentials.captain_id,
      created_at:credentials.created_at,
      code:credentials.code,
      due_date:credentials.due_date,
      execution_date:credentials.execution_date,
      status:credentials.status,
      type:credentials.type,
    });
    await commit('SET_MISSIONS', response.data.response.feed);
    commit('FEED_COUNT_MISSIONS', response.data.response.feed_count);
    commit('SUCCESS', response.data.response.success);
  },

  // submit edit Order
  async editOrderSubmit({ commit, state }, credentials) {
    const response = await axios.post(`order/edit/submit`, credentials);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      let err_msg = response.data.err_msg || response.data.message;
      commit('SET_ERROR_MSG', err_msg)
    } else {
      const order = { ...state.detail_of_order, ...credentials };
      commit('DETAIL_OF_ORDER', order);
      commit('SET_SUCCESS_API', 1);
    }
  },

  // Delete Order
  async deleteOrder({ commit }, credentials) {
    const response = await axios.post(`order/archive?id=${credentials}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('DELETE_ORDER', credentials);
      commit('SET_SUCCESS_API', 1);
    }
  },

  // fetch orders data to print it
  async printOrder({ commit }, credentials) {
    try {
      const response = await axios.post('order/print', credentials);
      if (response.data.success == 0) {
        commit('SET_SUCCESS_API', 0);
      } else {
        commit('SET_PRINT_ORDER', response.data.response);
        commit('SET_SUCCESS_API', 1);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  // fetch details of order
  async orderDetails({ commit }, credentials) {
    try {
      const response = await axios.post(`order/web-details?code=${credentials}`);
      commit('SET_SUCCESS_API', response.data.success);
      if (response.data.success) {
        commit('DETAIL_OF_ORDER', response.data.feed);
        commit('SET_ERROR_MSG', '');
      } else {
        commit('DETAIL_OF_ORDER', {});
        commit('SET_ERROR_MSG', response.data.error_msg);
      }
    } catch (err) {
      //
    } finally {
      //
    }
  },

  // Delay log of order details
  async orderDelayLog({ commit }, credentials) {
    const response = await axios.post(`order/delay-log?code=${credentials}`);
    if (response.data.success) {
      commit('SET_DELAY_LOG', response.data.feed);
    }

    commit('SET_SUCCESS_API', response.data.success);
  },

  // change order status to sent
  async changeOrderStatus({ commit }, credentials) {
    const response = await axios.post(`order/change-to-sent?id=${credentials}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_ERROR_MSG', response.data.error_msg);
    } else {
      commit('DELETE_ORDER', credentials);
      commit('SET_SUCCESS_API', 1);
      commit('SET_ERROR_MSG', '');
    }
  },

  // change order status to saved
  async changeOrderStatusSaved({ commit }, credentials) {
    const response = await axios.post(`order/change-to-saved?id=${credentials}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_ERROR_MSG', response.data.error_msg);
    } else {
      commit('DELETE_ORDER', credentials);
      commit('SET_SUCCESS_API', 1);
      commit('SET_ERROR_MSG', '');
    }
  },

  async fetchDataClientCosts({ commit }, credentials) {
    let response = "";
    if (credentials.pageNum != null) {
      response = await axios.post(
        `costs/client?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}`
      );
    } else {
      response = await axios.post(
        `costs/client?perPage=${credentials.itemsPerPage}`
      );
    }
    commit("SET_CLIENT_COSTS", response.data.response.feed);
    commit("FEED_COUNT_CLIENT_COSTS", response.data.response.feed_count);
  },

  async searchClientCosts({ commit }, credentials = {}) {
    let response = "";
    if (credentials.pageNum != null) {
      response = await axios.post(
        `costs/client?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}&name=${credentials.query}`
      );
    } else {
      response = await axios.post(
        `costs/client?perPage=${credentials.itemsPerPage}&name=${credentials.query}`
      );
    }
    commit("SET_CLIENT_COSTS", response.data.response.feed);
    commit("FEED_COUNT_CLIENT_COSTS", response.data.response.feed_count);
  },

  async fetchDataOfficeCosts({ commit }, credentials) {
    let response = "";
    if (credentials.pageNum != null) {
      response = await axios.post(
        `costs/office?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}`
      );
    } else {
      response = await axios.post(
        `costs/office?perPage=${credentials.itemsPerPage}`
      );
    }
    commit("SET_OFFICE_COSTS", response.data.response.feed);
    commit("FEED_COUNT_OFFICE_COSTS", response.data.response.feed_count);
  },

  async searchOfficeCosts({ commit }, credentials = {}) {
    let response = "";
    if (credentials.pageNum != null) {
      response = await axios.post(
        `costs/client?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}&name=${credentials.query}`
      );
    } else {
      response = await axios.post(
        `costs/client?perPage=${credentials.itemsPerPage}&name=${credentials.query}`
      );
    }
    commit("SET_CLIENT_COSTS", response.data.response.feed);
    commit("FEED_COUNT_CLIENT_COSTS", response.data.response.feed_count);
  },

  // fetch missions items
  async fetchDataMissions({ commit, state }, credentials) {
    let response = '';
    if (credentials.pageNum != null) {
      response = await axios.post(
        `mission/all?max_mission_updated_at=${state.max_mission_updated_at}&page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}`
      );
    } else {
      response = await axios.post(`mission/all?max_mission_updated_at=null&perPage=${credentials.itemsPerPage}`);
    }
    commit('SET_MISSIONS', response.data.response.feed);
    commit('FEED_COUNT_MISSIONS', response.data.response.feed_count);
    if (response.data.response.max_mission_updated_at) {
      commit('SET_MAX_MISSION_UPDATED_AT', response.data.response.max_mission_updated_at);
    }
  },

  // fetch address of Mission & bank accounts & payment ways & see if supplied mission available or not
  async fetchAddressMissions({ commit }) {
    const response = await axios.post(`mission/add`);
    commit('SET_ADDRESS_MISSIONS', response.data.addresses);
    commit('SET_BANK_ACCOUNTS', response.data.accounts);
    commit('SET_PAYMENT_WAY', response.data.paymentWays);
    commit('SET_MISSIONS_WITHIN_TWO_DAYS', response.data.missionsWithinTwoDays);
    commit('SET_SUPPLIED_AVAILABLE', response.data.suppliedAvailable);
    commit('SET_SUPPLY_DAYS', response.data.supply_days);
  },

  // submit mission
  async postMission({ commit }, credentials) {
    if (!credentials.month) delete credentials.month;
    if (!credentials.due_date) delete credentials.due_date;
    const response = await axios.post(`mission/submit`, credentials);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_ERROR_MSG', response.data.error_msg);
    } else {
      commit('SET_SUCCESS_API', 1);
    }
  },

  // fetch mission Details
  async missionDetails({ commit }, credentials) {
    try {
      const response = await axios.post(`mission/details?id=${credentials}`);
      commit("DETAIL_OF_MISSION", response.data.feed);
      commit("BRANCH_OF_MISSION", response.data.branch.name);
      commit("CLIENT_OF_MISSION", response.data.client.name);
      commit('SET_SUCCESS_API', response.data.success);
      if (response.data.captain != null) {
        commit("CAPTAIN_OF_MISSION", response.data.captain.name);
      } else {
        commit("CAPTAIN_OF_MISSION", response.data.captain);
      }
    } catch (err){
      commit('SET_SUCCESS_API', response.data.success);
      commit('SET_ERROR_MSG' ,err)
    }
  },

  // Delete mission
  async deleteMission({ commit }, credentials) {
    const response = await axios.post(`mission/terminate?id=${credentials.id}&reason_id=${credentials.reasonId}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('DELETE_MISSION', credentials.id);
      commit('SET_SUCCESS_API', 1);
    }
  },

  // fetch orders assigned to the mission
  async orderForMission({ commit }, credentials) {
    const response = await axios.get(
      `mission/mission-orders?id=${credentials.id}&page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}`
    );
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('SET_ORDER_FOR_MISSION', response.data.feed);
      commit('FEED_COUNT', response.data.feed_count);
      commit('SET_SUCCESS_API', 1);
    }
  },

  // fetch papers assigned to the mission
  async papersForMission({ commit }, credentials) {
    const response = await axios.get(`mission/papers?id=${credentials}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
    } else {
      commit('SET_PAPERS_FOR_MISSION', response.data.feed);
      commit('SET_SUCCESS_API', 1);
    }
  },

  // fetch details of mission to print it
  async fetchDataMissionPrint({ commit }, credentials) {
    const response1 = await axios.get(`mission/previews?id=${credentials}&type=1`);
    commit('SET_DATA_MISSION_PRINT1', response1.data.feed);
    const response2 = await axios.get(`mission/previews?id=${credentials}&type=2`);
    commit('SET_DATA_MISSION_PRINT2', response2.data.feed);
    const response3 = await axios.get(`mission/previews?id=${credentials}&type=3`);
    commit('SET_DATA_MISSION_PRINT3', response3.data.feed);
  },

  // fetch notifications itmes
  async fetchNotfication({ commit }, credentials) {
    const response = await axios.post(
      `notification/fetch?max_notification_id=0&page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}&type=${credentials.type}`
    );
    commit('SET_NOTFICATION', response.data.feed);
    commit('FEED_COUNT', response.data.feed_count);
  },

  // fetch faq itmes
  async fetchFaq({ commit }) {
    const response = await axios.post(`faq`);
    commit('SET_FAQ', response.data.faq);
  },

  // fetch system introduction videos
  async fetchVideos({ commit }) {
    await axios
      .post(`popular_videos`)
      .then(res => {
        if (res.data.success == 0) {
          commit('SET_SUCCESS_API', 0);
          commit('SET_MESSAGE', res.data.error_msg);
        } else {
          commit('SET_SUCCESS_API', 1);
          commit('SET_MESSAGE', '');
          commit('SET_VIDEOS', res.data.videos);
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_API', 0);
        commit('SET_MESSAGE', '');
      });
  },

  // fetch log for order
  async fetchLog({ commit }, credentials) {
    const response = await axios.get(`order/log?code=${credentials}`);
    commit('ORDER_LOG', response.data.feed);
  },

  // Chart
  async fetchChart({ commit }) {
    const response = await axios.get(`chart`);
    commit('SET_CHART', response.data.feed);
  },

  // fetch informaion account manager for client
  async fetchAccountManager({ commit }) {
    const response = await axios.post(`get-employee`);
    commit('SET_ACCOUNT_MANAGER', response.data.feed);
  },

  // fetch terms&conditions
  async fetchTermsAndCondition({ commit }) {
    const response = await axios.get(`pages/1`);
    commit('SET_TERMS_CONDITIONS', response.data.feed);
  },

  // Search missions
  async searchMission({ commit, state }, credentials) {
    let response = '';
    if (credentials.pageNum != null) {
      response = await axios.post(
        `mission/advanced-search-submit?page=${credentials.pageNum}&perPage=${credentials.itemsPerPage}&max_mission_updated_at=${state.max_mission_updated_at}`,
        {
          code: credentials.code,
        }
      );
    } else {
      response = await axios.post(
        `mission/advanced-search-submit?perPage=${credentials.itemsPerPage}&max_mission_updated_at=null`,
        {
          code: credentials.code,
        }
      );
    }
    commit('SET_SEARCH_MISSION', response.data.response.feed);
    // commit("FEED_COUNT", response.data.response.feed_count);
    commit('SET_SUCCESS_API', response.data.response.success);
    if (response.data.response.success == 0) {
      commit('SET_MESSAGE', response.data.response.error_msg);
    } else {
      commit('SET_MESSAGE', '');
    }
  },

  // fetch all branches
  async getBranches({ commit }, credentials) {
    const response = await axios.get(`branch/get?search=${credentials.search}&page=${credentials.page}`);
    commit('SET_BRANCHES', response.data.feed.data);
  },

  // fetch all captains
  async getCaptains({ commit }, credentials) {
    const response = await axios.get(`captain/get?search=${credentials.search}&page=${credentials.page}`);
    commit('SET_CAPTAINS', response.data.feed.data);
  },
  // fetch all captaisn for missions page
  async getAllCaptains({ commit }) {
    const response = await axios.get(`captain/selectCaptains?search=`);
    commit('SET_CAPTAINS', response.data.feed.data);
  },

  // excel mission
  async excelMission({ commit }, credentials) {
    var formData = new FormData();
    formData.append('date_from', credentials.dateFrom);
    formData.append('date_to', credentials.dateTo);
    const response = await axios.post('mission/export-my-missions', formData);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.message);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.message);
    }
  },

  // excel orders
  async excelOrder({ commit }, credentials) {
    var formData = new FormData();
    formData.append('date_from', credentials.dateFrom);
    formData.append('date_to', credentials.dateTo);
    formData.append('type', credentials.type);
    const response = await axios.post('order/export-my-orders', formData);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.message);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.message);
    }
  },

  // excel attend employees
  async excelAttendEmployee({ commit }, credentials) {
    var formData = new FormData();
    formData.append('date_from', credentials.dateFrom);
    formData.append('date_to', credentials.dateTo);
    const response = await axios.post('user/export-attendance', formData);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.message);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.message);
    }
  },

  // excel preview (اكسيل المعينات , اكسيل الطرود المرتجعة , اكسيل الطرود المسلمة , اكسيل  الاوراق المالية)
  async excelPreview({ commit }, credentials) {
    const response = await axios.get(`mission/excel?id=${credentials.id}&type=${credentials.type}`);
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.message);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.message);
    }
  },

  // request to cancel order
  async orderCancel({ commit }, credentials) {
    const response = await axios.post(`order/canceled`, {
      type: credentials.type,
      id: credentials.id,
    });
    if (response.data.success == false) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.error_msg);
    } else {
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.error_msg);
    }
  },

  // fetch notification settings
  async controlNotification({ commit }) {
    const response = await axios.get('notification/settings');
    if (response.data.success == 0) {
      commit('SET_SUCCESS_API', 0);
      commit('SET_MESSAGE', response.data.error_msg);
    } else {
      commit('SET_NOTIFICATION_CONTROL', response.data.feed);
      commit('SET_SUCCESS_API', 1);
      commit('SET_MESSAGE', response.data.error_msg);
    }
  },

  // edit notification setting
  async saveNotification({ commit }, credentials) {
    await axios
      .post('notification/save-settings', {
        type: credentials.type,
        status: credentials.status,
      })
      .then(res => {
        if (res.data.success == 0) {
          commit('SET_SUCCESS_API', 0);
          commit('SET_MESSAGE', res.data.error_msg);
        } else {
          commit('SET_UPDATE_NOTIFICATION', credentials);
          commit('SET_SUCCESS_API', 1);
          commit('SET_MESSAGE', res.data.error_msg);
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_API', 0);
      });
  },

  // rate order by client
  async rateOrder({ commit }, credentials) {
    await axios
      .post('order/client_rate', {
        order_id: credentials.order_id,
        rate: credentials.rate,
        note: credentials.note,
      })
      .then(res => {
        if (res.data.success == 0) {
          commit('SET_SUCCESS_API_RATE', 0);
          commit('SET_MESSAGE', res.data.error_msg);
        } else {
          commit('SET_SUCCESS_API_RATE', 1);
          commit('SET_MESSAGE', res.data.message);
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_API_RATE', 0);
        commit('SET_MESSAGE', err.response.data.error_msg);
      });
  },

  // request to resend order
  async resendRequest({ commit }, credentials) {
    await axios
      .post('order/resend-request', {
        id: credentials.id,
        type: credentials.type,
        order_id: credentials.orderId,
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data.success == false) {
            commit('SET_SUCCESS_RESEND', 0);
            commit('SET_MESSAGE', res.data.error_msg);
          } else {
            commit('SET_SUCCESS_RESEND', 1);
            commit('SET_MESSAGE', res.data.error_msg);
          }
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_RESEND', 0);
        commit('SET_MESSAGE', err.response.data.error_msg);
      });
  },

  // change order status to urgent
  async changeToUrgent({ commit }, credentials) {
    await axios
      .post('order/change-to-urgent', {
        id: credentials,
      })
      .then(res => {
        if (res.data.success == false) {
          commit('SET_SUCCESS_API', 0);
          commit('SET_ERROR_MSG', res.data.error_msg);
        } else {
          commit('SET_SUCCESS_API', 1);
          commit('SET_ERROR_MSG', res.data.error_msg);
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_API', 0);
        commit('SET_ERROR_MSG', err.response.data.error_msg);
      });
  },

  // Executing a return action on the sender from chat
  async returnOnSender({ commit }, credentials) {
    await axios
      .post('order/return-on-sender/save', credentials)
      .then(res => {
        if (res.data.success) {
          commit('SET_SUCCESS_API', 1);
          commit('SET_ERROR_MSG', '');
        }
      })
      .catch(err => {
        commit('SET_SUCCESS_API', 0);
        commit('SET_ERROR_MSG', err.response.data.error_msg);
      });
  },

  // get reasons of cancelation mission
  async cancelMissionReasons({ commit, state }, isAppend) {
    await axios
      .get(state.next_page_url_cancelation_reasons)
      .then(res => {
        if (res.data.success) {
          if (isAppend) {
            commit('MISSION_CANCELATION_REASOANS', [...state.mission_canelation_reasons, ...res.data.feed.data]);
          } else {
            commit('MISSION_CANCELATION_REASOANS', res.data.feed.data);
          }
          commit('ERR_MISSION_CANCEL_REASONS', false);
          commit('NEXT_PAGE_URL_CANCELATION_REASONS', res.data.feed.next_page_url);
        }
      })
      .catch(err => {
        commit('ERR_MISSION_CANCEL_REASONS', err);
        console.log(err.message);
      });
  },

  // get reasons of mission termination
  async missionTerminationReasons({ commit }) {
    const response = await axios.get(`/mission/termination-reasons`);
    commit('MISSION_CANCELATION_REASOANS', response.data.feed);
  },
};
