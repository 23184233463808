export default {
  SET_ORDERS (state, orders) {
    for(let i = 0; i < orders.length; i++) {
      for (const property in orders[i]) {
        if(orders[i][property] == null) {
          orders[i][property] = "لا يوجد"
        }
      }
    }
    state.orders = orders
  },
  FEED_COUNT: (state, feed_count) => (state.feed_count = feed_count),
  FEED_COUNT_MISSIONS: (state, feed_count_missions) => (state.feed_count_missions = feed_count_missions),
  SET_GOV: (state, governments) => (state.governments = governments),
  SET_PAYLOAD: (state, payload) => (state.payload = payload),
  ORDER_DETAILS: (state, order_details) => (state.order_details = order_details),
  FEED_COUNT_CLIENT_COSTS: (state, feed_count_costs) => (state.feed_count_costs = feed_count_costs),
  FEED_COUNT_OFFICE_COSTS: (state, feed_count_costs) => (state.feed_count_costs = feed_count_costs),
  SET_CLIENT_COSTS (state, costs) {
    for (let i = 0; i < costs.length; i++) {
      for (const property in costs[i]) {
        if (costs[i][property] == null) {
          costs[i][property] = "لا يوجد"
        }
      }
    }
    state.costs = costs
  },
  SET_OFFICE_COSTS (state, costs) {
    for (let i = 0; i < costs.length; i++) {
      for (const property in costs[i]) {
        if (costs[i][property] == null) {
          costs[i][property] = "لا يوجد"
        }
      }
    }
    state.costs = costs
  },
  SET_MISSIONS (state, missions) {
    for(let i = 0; i < missions.length; i++) {
      for (const property in missions[i]) {
        if(missions[i][property] == null) {
          missions[i][property] = "لا يوجد"
        }
      }
    }
    state.missions = missions
  },
  SET_ADDRESS_MISSIONS: (state, address_of_mission) => (state.address_of_mission = address_of_mission),
  SET_MISSIONS_WITHIN_TWO_DAYS: (state, missions_withiin_two_days) => (state.missions_withiin_two_days = missions_withiin_two_days),
  SET_SUPPLIED_AVAILABLE: (state, suppleid_available) => (state.suppleid_available = suppleid_available),
  SET_BANK_ACCOUNTS: (state, bank_accounts) => (state.bank_accounts = bank_accounts),
  SET_PAYMENT_WAY: (state, payment_way) => (state.payment_way = payment_way),
  DELETE_MISSION (state, id) {
    state.missions.splice(state.missions.findIndex(m => m.id === id), 1)
  },
  SET_COSTS(state, data) {
    for (let i = 0; i < data.length; i++) {
      for (const property in data[i]) {
        if (data[i][property] == null) {
          data[i][property] = "لا يوجد"
        }
      }
    }
    state.costs = data;
  },
  DELETE_ORDER (state, id) {
    state.orders.splice(state.orders.findIndex(o => o.id === id), 1)
  },
  SET_PRINT_ORDER: (state, print_order) => (state.print_order = print_order),
  SET_SUCCESS_API: (state, value) => (state.successApi = value),
  SET_NOTFICATION: (state, notfication) => (state.notfication = notfication),
  DETAIL_OF_ORDER: (state, detail_of_order) => (state.detail_of_order = detail_of_order),
  DETAIL_OF_MISSION: (state, detail_of_mission) => (state.detail_of_mission = detail_of_mission),
  SET_FAQ (state, faq) {
    state.faq = faq
    for(let i = 0; i < faq.length; i++) {
      state.faq[i].title = state.faq[i].question
    }
  },
  BRANCH_OF_MISSION: (state, branch_mission) => (state.branch_mission = branch_mission),
  CLIENT_OF_MISSION: (state, client_mission) => (state.client_mission = client_mission),
  CAPTAIN_OF_MISSION: (state, captain_mission) => (state.captain_mission = captain_mission),
  SUCCESS: (state, success) => (state.success = success),
  ORDER_LOG: (state, log) => (state.log = log),
  SET_ORDER_FOR_MISSION: (state, order_for_mission) => (state.order_for_mission = order_for_mission),
  SET_PAPERS_FOR_MISSION: (state, papers_for_mission) => (state.papers_for_mission = papers_for_mission),
  SET_DATA_MISSION_PRINT1: (state, data_mission_print1) => (state.data_mission_print1 = data_mission_print1),
  SET_DATA_MISSION_PRINT2: (state, data_mission_print2) => (state.data_mission_print2 = data_mission_print2),
  SET_DATA_MISSION_PRINT3: (state, data_mission_print3) => (state.data_mission_print3 = data_mission_print3),
  SET_CHART: (state, chart) => (state.chart = chart),
  SET_DELAY_LOG: (state, delay_log) => (state.delay_log = delay_log),
  SET_ACCOUNT_MANAGER: (state, account_manager) => (state.account_manager = account_manager),
  SET_TERMS_CONDITIONS: (state, terms_and_conditions) => (state.terms_and_conditions = terms_and_conditions),
  SET_ORDER_CODE: (state, order_code) => (state.order_code = order_code),
  SET_ERROR_MSG: (state, err_msg) => (state.err_msg = err_msg),
  SET_SUCCESS_MSG: (state, success_msg) => (state.success_msg = success_msg),
  SET_SEARCH_MISSION: (state, search_mission) => (state.search_mission = search_mission),
  SET_MAX_ORDER_UPDATED_AT: (state, max_order_updated_at) => (state.max_order_updated_at = max_order_updated_at),
  SET_MAX_MISSION_UPDATED_AT: (state, max_mission_updated_at) => (state.max_mission_updated_at = max_mission_updated_at),
  SET_BRANCHES: (state, branches) => (state.branches = branches),
  SET_CAPTAINS: (state, captains) => (state.captains = captains),
  SET_MESSAGE: (state, message) => (state.message = message),
  SET_NOTIFICATION_CONTROL (state, notification_control) {
    for (let i = 0; i < notification_control.length; i++) {
      if(notification_control[i].status == 1) {
        notification_control[i].status = true
      }else {
        notification_control[i].status = false
      }
    }
    state.notification_control = notification_control
  },
  SET_UPDATE_NOTIFICATION (state, notification_control) {
    let status;
    if (notification_control.status) {status = true}else {status = false}
    state.notification_control.map((el) => {
      if (el.type == notification_control.type){
        el.status = status
      }
    })
  },
  SET_SUCCESS_RESEND: (state, success_resend) => (state.success_resend = success_resend),
  SET_VIDEOS: (state, videos) => (state.videos = videos),
  SET_SUCCESS_API_RATE: (state, success_api_rate) => (state.success_api_rate = success_api_rate),
  MISSION_CANCELATION_REASOANS: (state, mission_canelation_reasons) => (state.mission_canelation_reasons = mission_canelation_reasons),
  ERR_MISSION_CANCEL_REASONS: (state, err_mission_cancel_reasons) => (state.err_mission_cancel_reasons = err_mission_cancel_reasons),
  NEXT_PAGE_URL_CANCELATION_REASONS: (state, next_page_url_cancelation_reasons) => (state.next_page_url_cancelation_reasons = next_page_url_cancelation_reasons),
  SET_SUPPLY_DAYS: (state, supply_days) => (state.supply_days = supply_days),
  SET_UNPREDICTED_GEOS: (state, unpredicted_geos) => (state.unpredicted_geos = unpredicted_geos),
  SET_SUCCESS_API_CONFIRM_SUGGEST_AREA: (state, success_api_confirm_suggest_area) => (state.success_api_confirm_suggest_area = success_api_confirm_suggest_area)
}
