import axios from 'axios'
import router from './router';
// import * as Sentry from "@sentry/vue";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL; // 'https://backoffice.turbo-eg.com/api/client/'
let userData = JSON.parse(localStorage.getItem('userData'))
if (userData) {
  const token = userData.token
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  // const name = JSON.parse(localStorage.getItem('userData')).name;
  // const id = JSON.parse(localStorage.getItem('userData')).clientId;
  // const email = JSON.parse(localStorage.getItem('userData')).email;

  // Sentry.setUser({ email: name });
  // Sentry.setTag("id", id);
  // Sentry.setTag("email", email);
}

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.data.logout) {
    localStorage.removeItem("userData");
    localStorage.removeItem("walkthrough")
    router.push({name: "page-login"})
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status == 401) {
    localStorage.removeItem("userData");
    localStorage.removeItem("walkthrough")
    router.push({name: "page-login"})
  }
  return Promise.reject(error);
});