import axios from "axios";
export default {
  async login({ commit, dispatch }, credentials) {
    const response = await axios.post(`login`, {
      email: credentials.email,
      password: credentials.password,
    });
    commit('STATUS_CODE', response.data.status ? response.data.status : 0);
    if (response.data.success == 0) {
      commit("ERROR_MSG", response.data.error_msg);
      // check if account deleted by admin
      if (response.data.phone1 && response.data.phone2) {
        commit("DELETED_ACCOUNT", true);
        // if account deleted store support phone
        commit("phone1", response.data.phone1);
        commit("phone2", response.data.phone2);
      }
    } else {
      commit("ERROR_MSG", "");
      commit("DELETED_ACCOUNT", false);
      const userData = {
        token: response.data.access_token,
        firstLogin: response.data.is_first_login,
        role: response.data.is_main_client,
        apiToken: response.data.api_token,
        userId: response.data.user_id,
        clientId: response.data.client_id,
        name: response.data.client_name,
        email: response.data.email,
        hasCompany: response.data.has_company,
        company: response.data.company.settings,
        restored: response.data.restored,
        availableSurveys: response.data.available_surveys,
        idToken: response.data.id_token
      };
      // set if available surveys or not
      localStorage.setItem("availableSurveys", response.data.available_surveys);
      if (localStorage.getItem("activeTurboShop")) {}
      else {
        localStorage.setItem("activeTurboShop", "show");
        dispatch("updateTurboShopShow", true, { root: true });
      }
      // restored => To see if the account was erased and retrieved or not
      localStorage.setItem("restored", response.data.restored);
      localStorage.setItem("walkthrough", response.data.walkthrough);
      await localStorage.setItem("userData", JSON.stringify(userData));
    }
  },
  logout({ dispatch }) {
    dispatch("updateWalkthrough", false, { root: true });
    localStorage.clear();
    localStorage.setItem("activeTurboShop", "show");
  },
  async forgetPassword({ commit }, credentials) {
    let response;
    try {
      response = await axios.post(`send-otp`, {
        email: credentials,
      });
      if (response.status == 200 || response.status == 201) {
        commit("SET_SUCCESS", response.data.success);
        commit("SET_PHONE", response.data.phone);
        commit("SET_EMAIL", credentials);
        commit("ERROR_MSG", "");
      } else {
        commit("SET_SUCCESS", 0);
        commit("ERROR_MSG", response.data.error_msg);
      }
    } catch (e) {
      commit("SET_SUCCESS", 0);
      commit("ERROR_MSG", e.response.data.error_msg);
    }
  },
  async checkOtp({ commit }, credentials) {
    let response;
    try {
      response = await axios.post("check-otp", {
        email: credentials.email,
        code: credentials.code,
      });
      if (response.status == 200 || response.status == 201) {
        commit("SET_SUCCESS", response.data.success);
        commit("ERROR_MSG", "");
        commit("SET_CODE", credentials.code);
      } else {
        commit("SET_SUCCESS", 0);
        commit("ERROR_MSG", response.data.error_msg);
      }
    } catch (e) {
      commit("SET_SUCCESS", 0);
      commit("ERROR_MSG", e.response.data.error_msg);
    }
  },
  async resetPassword({ commit }, credentials) {
    let response;
    try {
      response = await axios.post("reset", {
        code: credentials.code,
        password: credentials.password,
        password_confirmation: credentials.passwordConfirmation,
      });
      if (response.status == 200 || response.status == 201) {
        commit("SET_SUCCESS", response.data.success);
        commit("ERROR_MSG", "");
      } else {
        commit("SET_SUCCESS", 0);
        commit("ERROR_MSG", response.data.error_msg);
      }
    } catch (e) {
      commit("SET_SUCCESS", 0);
      commit("ERROR_MSG", e.response.data.error_msg);
    }
  },
  async confirmRestored() {
    // To confirm account was retrieved
    await axios.post("restored").then((res) => {
      if (res.status == 200) {
        localStorage.setItem("restored", 0);
      }
    });
  },
};
