import state from './moduleSurveyState.js'
import mutations from './moduleSurveyMutations.js'
import actions from './moduleSurveyActions.js'
import getters from './moduleSurveyGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}