<template>
  <div id="app" :class="vueAppClasses" @click="inactiveTurboShop()">
    <router-view @setAppClasses="setAppClasses" />
    <button ref="notificationToast" :color="notficationColor" type="flat" style="display: none"
      @click="$vs.notify({
        title: notficationTitle,
        text: $t(notficationText),
        color: notficationColor,
        iconPack: 'feather',
        icon: notficationIcon || 'icon-check'
      })">
    </button>
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import helper from '@/mixins/helper.js'

export default {
  mixins: [helper],
  data () {
    return {
      vueAppClasses: [],
      connectionStatus: navigator.onLine
    }
  },
  watch: {
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    isOnline(){
      setInterval(()=>{
        var connectionStatus2 = navigator.onLine
        if(this.connectionStatus != connectionStatus2){
          if(connectionStatus2){
            this.notficationTost(true, 'successConnectionInternet')
            this.$refs.notificationToast.click()
          }else{
            this.notficationTost(false, 'failedConnectionInternet')
            this.$refs.notificationToast.click()
          }
        }
        this.connectedStatus = navigator.onLine
      }, 2000)
    },
    inactiveTurboShop() {
      this.$store.dispatch('updateTurboShopShow')
      // localStorage.setItem("activeTurboShop", "hide")
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created () {

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
    this.isOnline()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>

<style lang="scss" scoped>
  #app{
    font-family: 'Cairo', sans-serif !important;
  }
</style>