export default {
    error_msg: '',
    success: '',
    phone: '',
    email: '',
    code: '',
    deleted_account: false,
    phone1: '',
    phone2: '',
    status_code: ''
}
