const dict = {
  custom: {
    product_name: {
      required: "حقل اسم المنتج مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    code_sky: {
      required: "حقل رمز ال SKU مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    receiver: {
      required: "حقل المرسل اليه مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    sender: {
      min: "ادخل علي الاقل 3 حروف",
    },
    government: {
      required: "حقل المحافظة مطلوب",
    },
    area: {
      required: "حقل المنطقة مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    address: {
      required: "حقل العنوان مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    contentOrder: {
      required: "حقل محتوي الطرد مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    number: {
      required: "حقل رقم الهاتف مطلوب",
      regex: "رقم الهاتف يجب أن يبدأ ب 01 او كود المحافظة",
      max: "رقم الهاتف يجب ان يكون 11 رقم",
      min: "يجب ادخال رقم هاتف صحيح",
      numeric: "يجب ادخال رقم هاتف صحيح",
      digits: "يجب ادخال رقم هاتف صحيح",
    },
    number2: {
      regex: "رقم الهاتف يجب أن يبدأ ب 01 او كود المحافظة",
      max: "رقم الهاتف يجب ان يكون 11 رقم",
      min: "يجب ادخال رقم هاتف صحيح",
      numeric: "يجب ادخال رقم هاتف صحيح",
      digits: "يجب ادخال رقم هاتف صحيح",
    },
    numbreTracking: {
      regex: "رقم الهاتف يجب أن يبدأ ب 01 او كود المحافظة",
    },
    collectedAmount: {
      numeric: "يجب ادخال ارقام 'اكبر من صفر'",
      max: "يجب ادخال 20 رقم بحد اقصي",
      required: "حقل المبلغ المطلوب تحصيلة مطلوب",
      min_value: "لا يمكن ادخال قيمة اقل من صفر",
      decimal:"يجب ادخال ارقام"
    },
    reasonCollectedAmount: {
      required: "حقل سبب المبلغ المطلوب تحصيلة مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    returnValue: {
      required: "حقل قيمة الارتجاع مطلوب",
      numeric: "يجب ادخال ارقام 'اكبر من صفر'",
      max: "يجب ادخال 20 رقم بحد اقصي",
    },
    email: {
      required: "البريد الالكتروني مطلوب",
      email: "يجب أن يكون البريد الإلكتروني علي هيئة (mail@domain.com)",
    },
    mobileFollow: {
      regex: "رقم الهاتف يجب أن يبدأ ب 0",
      max: "رقم الهاتف يجب ان يكون 11 رقم",
      min: "يجب ادخال رقم هاتف صحيح",
      numeric: "يجب ادخال رقم هاتف صحيح",
      digits: "يجب ادخال رقم هاتف صحيح",
    },
    description: {
      required: "حقل الملاحظات مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    password: {
      required: "كلمة المرور مطلوبة",
      min: "يجب ادخال 8 حروف علي الاقل",
    },
    oldPassword: {
      required: "كلمة المرور القديمة مطلوبة",
      min: "يجب ادخال 8 حروف علي الاقل",
    },
    newPassword: {
      required: "كلمة المرور الجديدة مطلوبة",
      min: "يجب ادخال 8 حروف علي الاقل",
    },
    returnNewPassword: {
      required: "يجب اعادة كتابة كلمة المرور الجديدة",
      min: "يجب ادخال 8 حروف علي الاقل",
    },
    radius: {
      required: "حقل نطاق البصمة مطلوب",
      regex: "حقل نطاق البصمة يجب ان يكون رقم",
    },
    latitude: {
      required: "حقل دائرة العرض مطلوب",
      regex: "حقل دائرة العرض يجب ان يكون رقم",
    },
    longitude: {
      required: "حقل خط الطول مطلوب",
      regex: "حقل خط الطول يجب ان يكون رقم",
    },
    name: {
      required: "حقل الاسم مطلوب",
    },
    confirmPassword: {
      required: "حقل إعادة كلمة المرور الجديدة مطلوب",
      confirmed: "يجب ان تكون متطابقة لكلمة المرور الاولي",
    },
    missionType: {
      required: "حقل نوع المهمة مطلوب",
    },
    dueDate: {
      required: "حقل تاريخ التنفيذ مطلوب",
    },
    paymentWay: {
      required: "حقل طريقة الدفع مطلوب",
    },
    bankAccount: {
      required: "حقل رقم الحساب مطلوب",
    },
    branch: {
      required: " حقل الفرع مطلوب",
    },
    updateClientData: {
      required: "هذا الحقل مطلوب",
      min: "ادخل علي الاقل 3 حروف",
    },
    cancelationReason: {
      required: "هذا الحقل مطلوب",
      min: "ادخل علي الاقل 10 حروف",
    }
  },
};

export default dict;
