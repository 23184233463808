import notifyState from "./notifyState";
export default {
  mixins: [notifyState],
  data() {
    return {
      notficationColor: "",
      notficationText: "",
      notficationTitle: "",
      notificationIcon: "",
    };
  },
  computed: {},
  methods: {
    copyText(nameOfRef) {
      let ref;
      if (this.$refs[nameOfRef].length) ref = this.$refs[nameOfRef][0];
      else ref = this.$refs[nameOfRef];
      ref.type = "text";
      ref.select();
      document.execCommand("copy");
      ref.type = "hidden";
      this.notifySuccess("copiesDone");
    },
    getComplaintStatusbackground(status) {
      if (status === 2) return "#707070"; // تم الاغلاق
      else if (status === 0) return "#28C76F"; //جديدة
      else if (status === 1) return "#FF9F43"; //جاري الحل
    },
    notifyMe(notfiyMsg, title = "Turbo", img) {
      let options = {
        body: notfiyMsg,
        icon: "notification-icon.jpg",
        sound: "Notification.mp3",
        badge: "fav-icon.png",
        dir: "rtl",
      };
      if (img) {
        img = process.env.VUE_APP_CDN_URL + img;
        options.icon = img;
      }
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(title, options);
        // this.playSound('Notification.mp3')
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            var notification = new Notification(title, options);
            // this.playSound('Notification.mp3')
          }
        });
      }
      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them any more.
    },
    playSound(url) {
      const audio = new Audio(url);
      audio.play();
    },
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    },
    notficationTost(value, text) {
      if (value) {
        this.notficationTitle = "نجاح";
        this.notficationColor = "success";
        this.notficationText = text;
        this.notificationIcon = "icon-check";
      } else {
        this.notficationTitle = "فشل";
        this.notficationColor = "danger";
        this.notficationText = text;
        this.notificationIcon = "icon-alert-circle";
      }
    },
  },
  created() {},
};
