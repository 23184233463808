export default {
  // CHAT ORDER
  conversations: (state) => state.conversations,
  chat: (state) => state.chat,
  info_chat: (state) => state.info_chat,
  // CHAT MISsION
  conversations_mission: (state) => state.conversations_mission,
  chat_mission: (state) => state.chat_mission,
  info_chat_mission: (state) => state.info_chat_mission,
  success_api: (state) => state.successApi,
  err_msg: (state) => state.err_msg
}
