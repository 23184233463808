// import axios from 'axios'
import axios from "axios";

export default {
  // get questions of survey
  async getSurvey({ commit }) {
    await axios
      .get(`survey-custom`)
      .then((res) => {
        commit("SURVEY", res.data.survey);
      })
      .catch((err) => {
        // commit("ERROR_SAMSA_ORDER", err.message);
      });
  },

  // submit survey
  async submitSurvey({ commit }, data) {
    await axios
      .post(`survey-custom`, data)
      .then((res) => {
        commit("SUCCESS_SUBMIT_SURVEY", res.data.success);
        commit("ERR_SURVEY", res.data.error_msg);
        commit("AVAILABLE_SURVEYS", res.data.available_surveys)
      })
      .catch((err) => {
        commit("SUCCESS_SUBMIT_SURVEY", err.response.data.success);
        commit("ERR_SURVEY", err.response.data.error_msg);
      });
  },
};
