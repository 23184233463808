export default {
  methods: {
    notifySuccess(text) {
      this.$vs.notify({
        title: this.$t("success"),
        text: this.$t(text),
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
      });
    },
    notifyFaild(text) {
      this.$vs.notify({
        title: this.$t("faild"),
        text: this.$t(text),
        color: "warning",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
    },
  },
};
