// import axios from 'axios'
import axios from "axios";
const nameApiTicket = "ticket";

export default {
  // get all (complains types) of ticket
  async complainsTypes({ commit }) {
    const response = await axios.get(`${nameApiTicket}/complains-types`);
    commit("SET_TYPES", response.data.feed);
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
  },

  // get all (inquiries types) of ticket
  async inquiriesTypes({ commit }) {
    const response = await axios.get(`${nameApiTicket}/inquiries-types`);
    commit("SET_TYPES", response.data.feed);
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
  },

  // add new ticket
  async createTicket({ commit }, credentials) {
    let response;
    try {
      const formData = new FormData();
      formData.append("description", credentials.description || null);
      formData.append("type", credentials.typeTicket || null);
      if (!credentials.missionComplaint) {
        formData.append("complaint_type_id", credentials.complainTypeId);
        formData.append(
          "complaint_type_title_id",
          credentials.complainTypeTitleId
        );
      }
      formData.append("entity_id", credentials.entityId || null);
      formData.append("inquiry_type_id", credentials.inquiryTypeId || null);
      formData.append(
        "mission_complaint",
        credentials.missionComplaint || null
      );
      if (credentials.image) {
        for (let x = 0; x < credentials.image.length; x++) {
          formData.append("image[]", credentials.image[x]);
        }
      }
      response = await axios.post(`${nameApiTicket}/create`, formData);
      if (response.status == 200 || response.status == 201) {
        commit("SET_LIVE_TICKET", response.data.feed);
        commit("SET_SUCCESS", response.data.success);
        commit("SET_ASSIGNED_USER", response.data.feed.assignedUser);
        commit("SET_ERROR_MSG", "");
        commit("SET_NOT_DELAY", response.data.not_delay);
      }
    } catch (e) {
      commit("SET_SUCCESS", 0);
      commit("SET_ERROR_MSG", e.response.data.error_msg);
      commit("SET_NOT_DELAY", e.response.data.not_delay);
      if (e.response.data.feed) {
        commit("SET_LIVE_TICKET", e.response.data.feed);
      } else {
        commit("SET_LIVE_TICKET", "");
      }
    }
  },

  // send new message (ticket chat)
  async replyTicket({ commit }, credentials) {
    var formData = new FormData();
    formData.append("ticket_id", credentials.id);
    formData.append("message", credentials.message);
    formData.append("image", credentials.image);
    formData.append("file_size", credentials.fileSize);
    formData.append("file_name", credentials.fileName);
    const response = await axios.post(`${nameApiTicket}/reply`, formData);
    commit("SET_LIVE_MESSAGE_DATA", response.data.feed);
    commit("SET_ASSIGNED_USER", response.data.feed.ticket.assigned_user);
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
  },

  // get all tickets
  async allTickets({ commit }, credentials) {
    const response = await axios.get(
      `${nameApiTicket}/get?status=${credentials.statusTicket}&page=${credentials.page}&id=${credentials.id}&seen_by_client=${credentials.isRead}`
    );
    commit("SET_TICKETS", response.data.feed.data);
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
  },

  // get ticket details
  async singleTicket({ commit }, credentials) {
    const response = await axios.get(
      `${nameApiTicket}/show?ticket_id=${credentials}`
    );
    commit("SET_SINGLE_TICKET", response.data.feed);
    commit("SET_REPLIES_TICKET", response.data.feed.replies);
    commit("SET_ASSIGNED_USER", response.data.feed.assigned_user);
  },

  // rate ticket by client
  async rateTicket({ commit }, credentials) {
    const response = await axios.post(`${nameApiTicket}/rate`, {
      ticket_id: credentials.id,
      rate: credentials.rate,
    });
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
  },

  // git log of ticket
  async logTicket({ commit }, credentials) {
    const response = await axios.get(
      `${nameApiTicket}/log?ticket_id=${credentials}`
    );
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
    commit("SET_TICKET_LOG", response.data.feed.logs);
  },

  // get number of unread messages for ticket chat
  async countUnreadTicket({ commit }) {
    const response = await axios.get(`${nameApiTicket}/unreaded_tickets`);
    commit("SET_SUCCESS", response.data.success);
    commit("SET_ERROR_MSG", response.data.error_msg);
    commit("SET_COUNT_UNREAD_TICKET", response.data.feed);
  },

  // save that the message has been seen
  async replaySeen({ commit }, credentials) {
    let response;
    try {
      response = await axios.post(`${nameApiTicket}/reply-seen`, {
        id: credentials,
      });
      if (response.status == 200 || response.status == 201) {
        commit("SET_SUCCESS", response.data.success);
        commit("SET_ERROR_MSG", "");
      }
    } catch (e) {
      commit("SET_SUCCESS", 0);
      commit("SET_ERROR_MSG", e.response.data.error_msg);
    }
  },

  // to show&hide ticket popup
  toggleShowTicket({ commit, state }) {
    if (state.showType) commit("SET_SHOW_TYPE", false);
    else commit("SET_SHOW_TYPE", true);
  },

  // to find which page the client is on
  setTicketPageIndex({ commit }, ticketPageIndex) {
    commit("SET_COUNT", ticketPageIndex);
  },

  // to change page index
  nextPage({ commit }) {
    commit("SET_NEXT_PAGE");
  },
  previousPage({ commit }) {
    commit("SET_PREVIOUS_PAGE");
  },

  // to save type problem of ticket
  setTypeProblem({ commit }, type) {
    commit("SET_TYPE_PROBLEM", type);
  },

  // to default setting (page index = 1 & type problem = null)
  newTicket({ commit }) {
    commit("SET_NEW_TICKET");
  },

  // to save sub title of ticket
  setSubTitle({ commit }, subTitle) {
    commit("SET_SUB_TITLE", subTitle);
  },

  // to save id of complain type
  setComplainTypeId({ commit }, complainTypeId) {
    commit("SET_COMPLAINT_TYPE_ID", complainTypeId);
  },

  // to save id of (complain type title)
  setComplainTypeTitleId({ commit }, complainTypeTitleId) {
    commit("SET_COMPLAIN_TYPE_TITLE_ID", complainTypeTitleId);
  },

  // to save type of ticket
  setTypeTicket({ commit }, typeTicket) {
    commit("SET_TYPE_TICKET", typeTicket);
  },

  // to save related model (if type of ticket complain) - (complain related with order or branch or mission or ...)
  setRelatedModel({ commit }, relatedModel) {
    commit("SET_RELATED_MODEL", relatedModel);
  },

  // to save id of inquiry type
  setInquiryTypeId({ commit }, inquiryTypeId) {
    commit("SET_INQUIRY_TYPE_Id", inquiryTypeId);
  },

  // to save entityId (if type of ticket complain)
  // if complain related with order so entityId = id of order
  // if complain related with branch so entityId = id of brnch
  // if complain related with order so entityId = id of mission
  // ...
  setEntityId({ commit }, entityId) {
    commit("SET_ENITY_ID", entityId);
  },

  // to save not delay (with copmlain in order)
  // to save this order not delayed
  setNotDelay({ commit }) {
    commit("SET_NOT_DELAY", false);
  },

  // get instructions of ticket
  async gitInstructions({ commit }) {
    await axios
      .get(`${nameApiTicket}/ticket-instructions`)
      .then((res) => {
        if (res.status == 200) {
          commit("SET_TICKET_INSTRUCTIONS", res.data.feed);
          commit("SET_ERR_INSTRUCTIONS", "");
        }
      })
      .catch((err) => {
        commit("SET_ERR_INSTRUCTIONS", err.message);
      });
  },
};
