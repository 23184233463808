import axios from "axios";
export default {
  // fetch all data of client
  async fetchUserData({ commit }) {
    const response = await axios.post(`web/profile`);
    commit("SET_CLIENT", response.data.user.user_client.client);
    commit("SET_USER", response.data.user);
    commit("SET_SUB_CLIENT", response.data.sub_client);
    commit("SET_SUB_USER", response.data.sub_user);
    commit("SET_RESPONSIBLE_BRANCH", response.data.responsibleBranch);
    commit("SET_GOVERNMENT", response.data.government);
    commit("SET_HAVE_STOCK", response.data.have_stock);
    commit("SET_NEW_SUPPORT_SYSTEM_USER", response.data.new_support_system_user);
    commit("SET_SUPPORT_SYSTEM_USER_ID", response.data.support_system_user_id);
    localStorage.setItem("haveStock", response.data.have_stock);
  },
  //fetch User Balance
  async userBalance({commit}){
    const response = await axios.get(`/balance`);
    commit("SET_NEXT_SUPPLY", response.data.feed.next_supply);
    commit("SET_TOTAL_SUPPLIED_BALANCE", response.data.feed.total_supplied_balance);
    commit("SET_TOTAL_BALANCE", response.data.feed.total_balance);
  },
  // change password
  async changePassword({ commit }, credentials) {
    const response = await axios.post(`change-password`, {
      old_password: credentials.oldPassword,
      password: credentials.password,
      logout_devices: credentials.logout_devices, // :boolean
    });
    if (response.data.success == 0) {
      commit("ERROR_MSG", response.data.error_msg);
      commit("SET_SUCCESS_API", 0);
    } else {
      commit("ERROR_MSG", "");
      commit("SET_SUCCESS_API", 1);
      const userData = {
        token: response.data.access_token,
        firstLogin: response.data.is_first_login,
        role: response.data.is_main_client,
        apiToken: response.data.api_token,
        userId: response.data.user_id,
        clientId: response.data.client_id,
        name: response.data.client_name,
        email: response.data.email,
        hasCompany: response.data.has_company,
        company: response.data.company.settings,
      };
      await localStorage.setItem("userData", JSON.stringify(userData));
      let data = JSON.parse(localStorage.getItem("userData"));
      const token = data.token;
      axios.defaults.headers.common["Authorization"] = `Bearer ` + token;
    }
  },

  // add sub user of client
  async addSubUser({ commit, state }, credentials) {
    if (credentials.client_attend_from_every_where === 1) {
      delete credentials.client_location_id;
    }
    await axios
      .post(`user/add`, credentials)
      .then((res) => {
        if (res.data.success === 1) {
          commit("SET_SUB_USER", [credentials, ...state.sub_user]);
          commit("SET_SUCCESS_API", 1);
          commit("ERROR_MSG", "");
        } else {
          throw new Error(res.data.error_msg);
        }
      })
      .catch((e) => {
        commit("SET_SUCCESS_API", 0);
        if (e.message) commit("ERROR_MSG", e.message);
        else commit("ERROR_MSG", e.response.data.error_msg);
      });
  },

  // delete sub user of client
  async deleteSubUser({ commit }, credentials) {
    const response = await axios.get(`user/delete?id=${credentials}`);
    if (response.data.success == 0) {
      commit("SET_SUCCESS_API", 0);
    } else {
      commit("DELETE_SUB_USER", credentials);
      commit("SET_SUCCESS_API", 1);
    }
  },

  // edit sub user of client
  async editSubUser({ commit, state }, credentials) {
    if (credentials.client_attend_from_every_where == 1) {
      credentials.client_location_id = null;
      credentials.region = null;
    } else if (credentials.client_attend_from_every_where == 0)
      delete credentials.client_attend_from_every_where;
    if (!credentials.password) delete credentials.password;
    await axios
      .post(`user/edit`, credentials)
      .then((res) => {
        commit(
          "SET_SUB_USER",
          state.sub_user.map((existedUser) => {
            return existedUser.id === credentials.id
              ? credentials
              : existedUser;
          })
        );
        commit("SET_SUCCESS_API", 1);
        commit("ERROR_MSG", "");
      })
      .catch((e) => {
        commit("SET_SUCCESS_API", 0);
        commit("ERROR_MSG", e.response.data.error_msg);
      });
  },

  // revoke token for sub user of client
  async revokeToken({ commit }, credentials) {
    await axios
      .post(`user/revoke_token`, credentials)
      .then((res) => {
        commit("SET_SUCCESS_API", 1);
        commit("ERROR_MSG", "");
      })
      .catch((e) => {
        commit("SET_SUCCESS_API", 0);
        commit("ERROR_MSG", e.response.data.error_msg);
        if (e.response.data.message)
          commit("ERROR_MSG", e.response.data.message);
      });
  },

  // update instant link with api of client
  async updateApi({ commit }, credentials) {
    let response;
    try {
      response = await axios.post(`/update-api`, {
        api_url: credentials.apiUrl,
        api_token: credentials.apiToken,
      });
      if (response.status == 200 || response.status == 201) {
        commit("SET_SUCCESS_API", response.data.success);
        commit("UPDATED_INFO", response.data.feed);
        commit("ERROR_MSG", "");
        commit("API_ERRORS", "");
      } else {
        commit("SET_SUCCESS_API", 0);
        commit("ERROR_MSG", response.data.error_msg);
        commit("API_ERRORS", response.data.errors);
      }
    } catch (e) {
      commit("SET_SUCCESS_API", 0);
      commit("ERROR_MSG", e.response.data.error_msg);
      commit("API_ERRORS", e.response.data.errors);
    }
  },

  // delete instant link with api of client
  async deleteClientApi({ commit }) {
    let response;
    response = await axios.post(`/delete-client-api`);
    try {
      if (response.status == 200 || response.status == 201) {
        commit("ERROR_MSG", "");
        commit("API_ERRORS", "");
      }
    } catch (e) {
      commit("SET_SUCCESS_API", 0);
      commit("ERROR_MSG", e.response.data.error_msg);
      commit("API_ERRORS", e.response.data.errors);
    }
  },

  // fetch all locations of client
  async clientLocations({ commit }) {
    await axios
      .get(`/locations`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          commit("SET_CLIENT_LOCATIONS", res.data.feed.data);
          commit("SET_SUCCESS_API", 1);
          commit("ERROR_MSG", "");
        }
      })
      .catch((e) => {
        commit("SET_SUCCESS_API", 0);
        commit("ERROR_MSG", e.response.data.error_msg);
      });
  },

  // update location of client
  async updateLocation({ commit, state }, credentials) {
    if (!credentials.latitude) credentials.latitude = null;
    if (!credentials.longitude) credentials.longitude = null;
    await axios
      .put(`/locations`, credentials)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          commit(
            "SET_CLIENT_LOCATIONS",
            state.client_locations.map((existedLocation) => {
              return existedLocation.id === credentials.id
                ? credentials
                : existedLocation;
            })
          );
          if (credentials.radius) commit("SET_USER_RADIUS", credentials.radius);
          commit("SET_SUCCESS_API", 1);
          commit("ERROR_MSG", "");
        }
      })
      .catch((e) => {
        commit("SET_SUCCESS_API", 0);
        commit("ERROR_MSG", e.response.data.error_msg);
      });
  },
};
