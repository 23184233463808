import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/Home',
        },
        {
          path: '/Home',
          name: 'Home',
          component: () => import('./views/Home.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/UncompletedOrder',
          name: 'Uncompleted-Order',
          component: () => import('./views/orders/UncompletedOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/UnderwayOrder',
          name: 'Underway-Order',
          component: () => import('./views/orders/UnderwayOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/SavedOrder',
          name: 'Saved-Order',
          component: () => import('./views/orders/SavedOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/SentOrder',
          name: 'Sent-Order',
          component: () => import('./views/orders/SentOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/DelayOrder',
          name: 'Delay-Order',
          component: () => import('./views/orders/DelayOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/WithCaptain',
          name: 'With-Captain',
          component: () => import('./views/orders/WithCaptain.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/GivenOrder',
          name: 'Given-Order',
          component: () => import('./views/orders/GivenOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/ResultAdvancedSearch',
          name: 'Result-Advanced-Search',
          component: () => import('./views/orders/ResultAdvancedSearch.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: 'OrderDetails/:id',
          name: 'Order-Details',
          component: () => import('./views/orders/OrderDetails.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/Log/:id/:page',
          name: 'Log',
          component: () => import('./views/orders/Log.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/ClientCosts',
          name: 'ClientCosts',
          component: () => import('./views/costs/ClientCosts.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/ClientOfficeCosts',
          name: 'ClientOfficeCosts',
          component: () => import('./views/costs/ClientOfficeCosts.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/Missions',
          name: 'Missions',
          component: () => import('./views/missions/Missions.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/MissionDetails/:id',
          name: 'Mission-Details',
          component: () => import('./views/missions/MissionDetails.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/Supplied',
          name: 'Supplied',
          component: () => import('./views/orders/Supplied.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/ReturnWithCompany',
          name: 'Return-With-Company',
          component: () => import('./views/returns/ReturnWithCompany.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/Return',
          name: 'Return',
          component: () => import('./views/returns/Return.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/Resent',
          name: 'Resent',
          component: () => import('./views/returns/Resent.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/reports/AllOrders',
          name: 'All-Orders',
          component: () => import('./views/reports/AllOrders.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/reports/Missions',
          name: 'Report-Missions',
          component: () => import('./views/reports/Missions.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/reports/attend-employee',
          name: 'Saved-AndSent-Orders',
          component: () => import('./views/reports/attendEmployee.vue'),
          meta: {
            rule: 'isAdmin'
          }
        },
        {
          path: '/addOrder',
          name: 'Add-Order',
          component: () => import('./views/orders/AddOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/addMission',
          name: 'Add-Mission',
          component: () => import('./views/missions/AddMission.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/AddOrderWithExcel',
          name: 'Add-Order-With-Excel',
          component: () => import('./views/orders/AddOrderWithExcel.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/AddMultipleOrdersSmartly',
          name: 'Add-Orders-Smartly',
          component: () => import('./views/orders/AddMultipleOrdersSmartly.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/setting',
          component: () => import('@/views/pages/Setting.vue'),
          meta: {
            rule: 'isPublic'
          },
          children: [
            {
              path: '/setting-main-info',
              name: 'setting-main-info',
              component: () => import('@/views/pages/setting/MainInfo.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/setting-change-password',
              name: 'setting-change-password',
              component: () => import('@/views/pages/setting/ChangePassword.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/setting-employee-accounts',
              name: 'setting-employee-accounts',
              component: () => import('@/views/pages/setting/EmployeeAccounts.vue'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: '/link-with-plugins',
              name: 'link-with-plugins',
              component: () => import('@/views/pages/setting/LinkWithPlugins.vue'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: '/link-with-apis/',
              component: () => import('@/views/pages/setting/LinkWithApis.vue'),
              meta: {
                rule: 'isAdmin'
              },
              children: [
                {
                  path: 'orders',
                  name: 'link-with-orders',
                  component: () => import('@/views/pages/setting/LinkWithApisOrders.vue'),
                  meta: {
                    rule: 'isAdmin'
                  }
                },
                {
                  path: 'tickets',
                  name: 'link-with-tickets',
                  component: () => import('@/views/pages/setting/LinkWithApisTickets.vue'),
                  meta: {
                    rule: 'isAdmin'
                  }
                },
              ]
            },
            {
              path: '/control-notification',
              name: 'control-notification',
              component: () => import('@/views/pages/setting/ControlNotification.vue'),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: '/faq/:name',
          name: 'faq',
          component: () => import('@/views/pages/Faq.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/termsAndConditions',
          name: 'terms-and-conditions',
          component: () => import('@/views/pages/TermsAndConditions.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/printOrder/',
          name: 'print-order',
          component: () => import('@/views/orders/PrintOrder.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/printSticker/',
          name: 'print-sticker',
          component: () => import('@/views/orders/PrintSticker.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/printMission/:id',
          name: 'print-mission',
          component: () => import('@/views/missions/PrintMission.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/apps/chat/:id',
          name: 'chat',
          component: () => import('./views/apps/chat/Chat.vue'),
          meta: {
            rule: 'isPublic',
            no_scroll: true
          }
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('@/views/products/Products.vue'),
          beforeEnter: async (to, from, next) => {
            if (JSON.parse(localStorage.getItem('haveStock')) === 1) {
              return next();
            }
            return next('/pages/error-404');
          },
          meta: {
            rule: 'isPublic'
          }
        },
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/layout-login',
          component: () => import('@/views/pages/login/layoutLogin.vue'),
          children: [
            {
              path: '/',
              redirect: '/login',
            },
            {
              path: '/login',
              name: 'page-login',
              component: () => import('@/views/pages/login/Login.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/confirm-mobile',
              name: 'confirm-mobile',
              component: () => import('@/views/pages/login/confirmMobile.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/otp',
              name: 'otp',
              component: () => import('@/views/pages/login/otp.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/change-password',
              name: 'change-password',
              component: () => import('@/views/pages/login/changePassword.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/changed-password',
              name: 'changed-password',
              component: () => import('@/views/pages/login/changedPassword.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
            {
              path: '/set_password',
              name: 'set-password',
              component: () => import('@/views/pages/login/SetPassword.vue'),
              meta: {
                rule: 'isPublic'
              }
            },
          ],
        },
        {
          path: '/publictermsandconditions',
          name: 'terms-and-conditions-client',
          component: () => import('@/views/pages/TermsAndConditions2.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'isPublic'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/publictermsandconditions', '/set_password', '/confirm-mobile', '/otp', '/change-password', '/changed-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem('userData'));

  if (authRequired && loggedIn) {
    if (loggedIn.firstLogin > 0) {
      return next();
    }
    return next('/set_password')
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.path == '/login' && loggedIn) {
    return next('/Home')
  }

  return next();
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
