import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

export default new AclCreate({
  initial  : 'public',
  notfound : '/pages/not-authorized',
  router,
  acceptLocalRules : true,
  globalRules: {
    isAdmin  : new AclRule('admin').or('adminCompany').generate(),
    isAdminCompany  : new AclRule('adminCompany').generate(),
    isPublicCompany : new AclRule('publicCompany').or('adminCompany').generate(),
    isPublic : new AclRule('public').or('admin').or('adminCompany').or('publicCompany').generate()
  },
  middleware: async acl => {
    let userData = JSON.parse(localStorage.getItem('userData'))
    if(userData){
      const role = userData.role
      const hasCompany = userData.hasCompany
      if(hasCompany) {
        if(role == 1){
          acl.change('adminCompany')
        }else{
          acl.change('publicCompany')
        }
      }else{
        if(role == 1){
          acl.change('admin')
        }else{
          acl.change('public')
        }
      }
    }
  }
})
