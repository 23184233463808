import state from "./moduleExternalApiState.js";
import mutations from "./moduleExternalApiMutations.js";
import actions from "./moduleExternalApiActions.js";
import getters from "./moduleExternalApiGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
