export default {
  // CHAT ORDER
  conversations: [],
  chat: [],
  successApi: '',
  info_chat: [],
  // CHAT MISSION
  conversations_mission: [],
  chat_mission: [],
  info_chat_mission: [],
  err_msg: ''
}
