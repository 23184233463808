export default {
  SET_CLIENT: (state, client) => (state.client = client),
  SET_USER: (state, user) => (state.user = user),
  SET_SUB_CLIENT: (state, sub_client) => (state.sub_client = sub_client),
  SET_SUB_USER: (state, sub_user) => (state.sub_user = sub_user),
  SET_RESPONSIBLE_BRANCH: (state, responsible_branch) =>
    (state.responsible_branch = responsible_branch),
  SET_GOVERNMENT: (state, government) => (state.government = government),
  ERROR_MSG: (state, error_msg) => (state.error_msg = error_msg),
  SET_NEXT_SUPPLY: (state, next_supply) => (state.next_supply = next_supply),
  SET_TOTAL_SUPPLIED_BALANCE: (state, total_supplied_balance) =>
    (state.total_supplied_balance = total_supplied_balance),
  SET_SUCCESS_API: (state, success_api) => (state.success_api = success_api),
  DELETE_SUB_USER(state, id) {
    state.sub_user.splice(
      state.sub_user.findIndex((m) => m.id === id),
      1
    );
  },
  SET_TOTAL_BALANCE: (state, total_balance) =>
    (state.total_balance = total_balance),
  API_ERRORS: (state, api_errors) => (state.api_errors = api_errors),
  UPDATED_INFO: (state, updated_info) => (state.updated_info = updated_info),
  SET_HAVE_STOCK: (state, have_stock) => (state.have_stock = have_stock),
  SET_NEW_SUPPORT_SYSTEM_USER: (state, new_support_system_user) => (state.new_support_system_user = new_support_system_user),
  SET_SUPPORT_SYSTEM_USER_ID: (state, new_support_system_user_id) => (state.support_system_user_id = new_support_system_user_id),
  SET_CLIENT_LOCATIONS(state, client_locations) {
    state.client_locations = client_locations;
  },
  SET_USER_RADIUS: (state, radius) => (state.user.radius = radius),
};
