import axios from "axios";
const actions = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },


  // The Navbar
  arrangeStarredPagesLimited ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo ({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateUserRole ({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {userRole: payload.userRole})
  },

  updateStepTour({ commit }, step){
    commit('SET_STEP_TOUR', step)
  },

  async walkthrough ({ dispatch }) {
    const response = await axios.post('user/walkthrough')
    if(response.data.success == 1) {
      dispatch('updateWalkthrough', true)
      localStorage.setItem("walkthrough", 1)
    }
  },
  
  updateWalkthrough({ commit }, value){
    commit('SET_WALKTHROUGH_VALUE', value)
  },
  updateTurboShopShow({ commit }, value) {
    commit('SET_TURBOSHOP_SHOW_VALUE', value)
  }
}

export default actions
