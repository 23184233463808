import axios from "axios";
export default {
  commitErros({ commit }, res, commitSuccess) {
    commit("SET_ERRORS", res.data.errors);
    commit("SET_ERROR_MSG", res.data.error_msg);
    commit(commitSuccess, res.data.success);
  },

  // fetch all governments
  async fetchGovernments({ commit, dispatch }) {
    await axios
      .get(`get-government`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit("SET_GOVERNMENTS", res.data.feed);
          commit("SET_SUCCESS_GOVERNMENTS", res.data.success);
        } else {
          dispatch("commitErros", res, "SET_SUCCESS_GOVERNMENTS");
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response, "SET_SUCCESS_GOVERNMENTS");
      });
  },

  // fetch all regions of the government
  async fetchRegions({ commit, dispatch }, governmentId) {
    await axios
      .get(`get-area/${governmentId}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          commit("SET_REGiONS", res.data.feed);
          commit("SET_SUCCESS_REGiONS", res.data.success);
        } else {
          dispatch("commitErros", res, "SET_SUCCESS_REGiONS");
        }
      })
      .catch((err) => {
        dispatch("commitErros", err.response, "SET_SUCCESS_REGiONS");
      });
  },
};
