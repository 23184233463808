import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// import moduleTodo from './todo/moduleTodo.js'
import moduleChat from "./chat/moduleChat.js";
import moduleData from "./data-list/moduleDataList.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleUser from "./user-management/moduleUserManagement";
import moduleTicket from "./ticket/moduleTicket";
import moduleProducts from "./products/moduleProducts";
import moduleSamsaApi from "./samsa-api/moduleSamsa";
import moduleExternalApi from "./external-api/moduleExternalApi";
import moduleSurvey from "./survey/moduleSurvey"

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    chat: moduleChat,
    dataList: moduleData,
    auth: moduleAuth,
    userData: moduleUser,
    ticket: moduleTicket,
    products: moduleProducts,
    samsaApi: moduleSamsaApi,
    externalApi: moduleExternalApi,
    survey: moduleSurvey,
  },
  strict: process.env.NODE_ENV !== "production",
});
