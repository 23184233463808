export default {
  SET_PRODUCTS(state, products) {
    products.map((product) => {
      product.quantity = 1;
      product.selected = false;
      product.product_id = product.id;
    });
    state.products = products;
  },
  SET_NEW_PRODUCTS(state, new_products) {
    new_products.map((product) => {
      product.quantity = 1;
      product.selected = false;
      product.product_id = product.id;
    });
    state.new_products = new_products;
  },
  SET_NEXT_PRODUCTS_URL: (state, next_products_url) =>
    (state.next_products_url = next_products_url),
  SET_NEXT_PRODUCTS_URL_SEARCH: (state, next_products_url_search) =>
    (state.next_products_url_search = next_products_url_search),
  SET_SUCCESS: (state, success) => (state.success = success),
  SET_ERRORS: (state, errors) => (state.errors = errors),
  SET_ERROR_MSG: (state, error_msg) => (state.error_msg = error_msg),
};
