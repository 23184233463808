import state from './moduleSamsaState.js'
import mutations from './moduleSamsaMutations.js'
import actions from './moduleSamsaActions.js'
import getters from './moduleSamsaGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}