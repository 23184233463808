import axios from 'axios'

// Send message by form data in (chat order) & (chat mission)
function postMessageData(credentials) {
  var formData = new FormData();
  formData.append("file", (credentials.image || null));
  formData.append("id", credentials.id);
  formData.append("message", credentials.message);
  formData.append("file_name", (credentials.fileName || null));
  formData.append("file_size", (credentials.fileSize || null));
  if (credentials.longitude) {
    formData.append("longitude", (credentials.longitude || null));
    formData.append("latitude", (credentials.latitude || null));
  }
  return formData
}

export default {
  // fetch order conversations
  async fetchConversation ({commit}, credentials) {
    const response = await axios.post(`conversations?max_message_id=null&page=${credentials.page}&is_read=${credentials.isRead}&code=${credentials.code}`)
    if(response.data.success == 0){
      commit('SET_SUCCESS_API', 0)
      commit('SET_ERROR_MESSAGE', response.data.error_msg)
    }else{
      commit('SET_SUCCESS_API', 1)
      commit('SET_ERROR_MESSAGE', '')
    }
    commit('SET_CONVERSATION', response.data.conversations)
  },

  // fetch converstaion messages & conversation information (chat order)
  async fetchChat ({ commit }, credentials) {
    const response = await axios.post(`order/messages?id=${credentials}`)
    commit('SET_CHAT', response.data.messages)
    commit('SET_INFO_CHAT', response.data)
  },
  
  // send message (chat order)
  async postMessage ({ commit }, credentials) {
    const response = await axios.post(`order/push-message`, postMessageData(credentials))
    if(response.data.success == 0){
      commit('SET_SUCCESS_API', 0)
    }else{
      commit('SET_SUCCESS_API', 1)
    }
  },

  // fetch mission conversations
  async fetchConversationMission ({ commit }, credentials) {
    const response = await axios.post(`mission_conversations?page=${credentials.page}&is_read=${credentials.isRead}&max_message_id=null&code=${credentials.code}`)
    commit('SET_CONVERSATION_MISSION', response.data.conversations)
    if(response.data.success == 0){
      commit('SET_SUCCESS_API', 0)
      commit('SET_ERROR_MESSAGE', response.data.error_msg)
    }else{
      commit('SET_SUCCESS_API', 1)
      commit('SET_ERROR_MESSAGE', '')
    }
  },

  // fetch converstaion messages & conversation information (chat mission)
  async fetchChatMission ({ commit }, credentials) {
    const response = await axios.post(`mission/messages?mission_code=${credentials}`)
    commit('SET_CHAT_MISSION', response.data.messages)
    commit('SET_INFO_CHAT_MISSION', response.data)
  },

  // send message (chat mission)
  async postMessageMission ({ commit }, credentials) {
    const response = await axios.post(`mission/push-message`, postMessageData(credentials))
    if(response.data.success == 0){
      commit('SET_SUCCESS_API', 0)
    }else{
      commit('SET_SUCCESS_API', 1)
    }
  }
}
